import { useEffect, useRef } from "react";
import Header from "../common/header";
import AboutMe from "../creator/AboutMe";
import Footer from "../creator/Footer";
import Hero from "../creator/Hero";
import Message from "../creator/Message";
import Projects from "../creator/Projects";
import Skills from "../creator/Skills";
import Work from "../creator/Work";

const Creator = () => {
  const socialRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    window.document.title = "Emman | Portfolio";
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      socialRef.current?.showModal();
    }
  }, [window.innerWidth]);

  const renderAlert = () => {
    return (
      <dialog ref={socialRef} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hi this is Inspect!</h3>
          <p className="py-4">
            The mobile version is currently underdevelopment. <br /> Thank you
            for your patience.
          </p>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
    );
  };

  return (
    <>
      {renderAlert()}
      <Header />
      <Hero />
      <Skills />
      <AboutMe />
      <Projects />
      <Work />
      <Message />
      <Footer />
    </>
  );
};

export default Creator;
