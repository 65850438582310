import { useRef } from "react";
import { Link } from "react-router-dom";

interface HeaderProps {
  title?: string;
}

const Header = (props: HeaderProps) => {
  const { title } = props;

  const socialRef = useRef<HTMLDialogElement>(null);

  const openModal = () => {
    socialRef.current?.showModal();
  };

  return (
    <div className="flex justify-center sticky top-2 z-50">
      <div className="navbar bg-base-100/50 m-5 w-3/4 rounded-lg shadow-md">
        <div className="navbar-start">
          <div className="dropdown">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-circle"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 bg-base-100/50"
            >
              <li>
                <Link to={"/tools"}>Resource</Link>
              </li>
              <li>
                <Link to={"/portfolio"}>Portfolio</Link>
              </li>
              <li>
                <a>Code Shop</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <a className="btn btn-ghost text-xl dark:text-white/80">
            {title ? title : "INSPECT"}
          </a>
        </div>
        <div className="navbar-end">
          <button className="btn btn-ghost" onClick={openModal}>
            Follow
            <svg
              className="h-5 w-5 stroke-current fill-current"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
            </svg>
          </button>
          <dialog ref={socialRef} className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Follow my Socials</h3>
              <div className="flex mt-10 justify-center gap-8">
                <Link
                  to={"https://www.facebook.com/officialInspectph"}
                  target="_blank"
                >
                  <div className="border border-current p-2 rounded-lg">
                    <svg
                      className="h-10 w-10 stroke-current fill-current"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                    </svg>
                  </div>
                </Link>
                <Link
                  to={
                    "https://www.linkedin.com/in/emmanuel-santiago-750584181/"
                  }
                  target="_blank"
                >
                  <div className="border border-current p-2 rounded-lg">
                    <svg
                      className="h-10 w-10 stroke-current fill-current"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                      <path d="M2 9h4v12H2z" />
                      <path d="M4 2a2 2 0 1 0 0 4 2 2 0 1 0 0-4z" />
                    </svg>
                  </div>
                </Link>
                <Link
                  to={"https://www.youtube.com/@inspect27?sub_confirmation=1"}
                  target="_blank"
                >
                  <div className="border border-current p-2 rounded-lg">
                    <svg
                      width={34}
                      height={34}
                      className="h-10 w-10 stroke-current fill-none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />
                      <path d="m9.75 15.02 5.75-3.27-5.75-3.27v6.54z" />
                    </svg>
                  </div>
                </Link>
                <Link to={"https://www.tiktok.com/@inspectph"} target="_blank">
                  <div className="border border-current p-2 rounded-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="tiktok"
                      className="h-10 w-10 stroke-current fill-none"
                    >
                      <path d="M38.4 21.68V16c-2.66 0-4.69-.71-6-2.09a8.9 8.9 0 0 1-2.13-5.64v-.41l-5.37-.13V30.8a5 5 0 1 1-3.24-5.61v-5.5a10.64 10.64 0 0 0-1.7-.14 10.36 10.36 0 1 0 10.36 10.36 10.56 10.56 0 0 0-.08-1.27v-9.15a14.48 14.48 0 0 0 8.16 2.19Z"></path>
                    </svg>
                  </div>
                </Link>
              </div>
              <div className="modal-action">
                <form method="dialog">
                  <button className="btn">Close</button>
                </form>
              </div>
            </div>
          </dialog>
          {/* <button className="btn btn-ghost btn-circle">
            <div className="indicator">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
              <span className="badge badge-xs badge-primary indicator-item"></span>
            </div>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
