import { Link } from "react-router-dom";
import developer from "../../assets/img/3d-developer-front.png";
const Message = () => {
  return (
    <div className="flex justify-center mt-60">
      <div className="card w-[62rem] bg-base-100">
        <div className="flex flex-col items-center gap-5">
          <div className="text-2xl text-center font-light leading-snug">
            I'm currently open to part-time and new opportunities. Whether you
            have a question or just want to say hi, feel free to reach out, and
            I'll do my best to get back to you!
          </div>
          <div>
            <Link
              to="mailto:eman15santiago@gmail.com"
              className="btn btn-ghost border border-current text-lg my-9"
            >
              Say Hi
              <svg
                className="2-h h-5 stroke-current"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22 2 11 13" />
                <path d="m22 2-7 20-4-9-9-4 20-7z" />
              </svg>
            </Link>
          </div>
          <figure>
            <img src={developer} alt="me" className="w-[35rem]" />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default Message;
