import { Link } from "react-router-dom";
import dicks from "../../assets/img/dicks-sporting-goods.png";
import richardson from "../../assets/img/richardson.png";
import samsung from "../../assets/img/samsung-logo.avif";
interface ProjectData {
  title: string;
  desc: string;
  link: string;
  img: string;
}

const Projects = () => {
  const featured_project: ProjectData[] = [
    {
      img: samsung,
      title: "Checkout Analytics Portal | Samsung",
      desc: "A fast and easy way to pay your contents in mobile devices",
      link: "https://www.samsung.com/us/",
    },
    {
      img: dicks,
      title: "DSG | Prolook Customizer",
      desc: "Superior Team Apparel",
      link: "https://www.dickssportinggoods.com/s/prolook-custom-team-uniforms-jerseys",
    },
    {
      img: richardson,
      title: "Richardson Uniform Customizer",
      desc: "Superior Team Apparel",
      link: "https://richardsonsports.com/",
    },
  ];

  const renderProject = (project: ProjectData, index: number) => {
    return (
      <div
        className="card w-80 bg-base-100 shadow-xl hover:scale-105 transition"
        key={index}
      >
        <figure className="px-1 pt-1">
          <img src={project.img} alt={project.title} className="rounded-xl " />
        </figure>
        <div className="card-body">
          <h2 className="card-title text-lg">{project.title}</h2>
          <p className="text-sm font-light">{project.desc}</p>
          <div className="card-actions justify-end">
            <Link target="_blank" to={project.link} className="btn btn-ghost">
              Visit{" "}
              <svg
                className="h-5 w-5 stroke-current"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 12h14" />
                <path d="m12 5 7 7-7 7" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center mt-28">
      <div className="card w-[75rem] bg-base-300/80 shadow-xl">
        <div className="card-body m-10">
          <h2 className="card-title text-3xl">
            Featured Projects
            <svg
              className="h-8 w-8 stroke-current"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m16.5 9.4-9-5.19" />
              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
              <path d="M3.27 6.96 12 12.01l8.73-5.05" />
              <path d="M12 22.08V12" />
            </svg>
          </h2>
          <div className="mt-12 flex justify-between">
            {featured_project.map(renderProject)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
