import gocloud from "../../assets/img/gocloud.png";
import innov from "../../assets/img/innovations-groups.png";
import qx from "../../assets/img/quickstrike.jpeg";
import samsung from "../../assets/img/samsung-logo.avif";
import starfish from "../../assets/img/starfish.png";
import vwala from "../../assets/img/vwala.png";
import yondu from "../../assets/img/yondu.jpg";

interface WorkData {
  image: string;
  title: string;
  type: string;
  company: string;
  years: string;
}

const works: WorkData[] = [
  {
    image: samsung,
    title: "Front-end Engineer (엔지니어)",
    company: "Samsung",
    years: "2023 - Current",
    type: "Full-time",
  },

  {
    image: vwala,
    title: "Front-end Developer",
    company: "vwala",
    years: "3 Months - 2023",
    type: "Contract",
  },
  {
    image: yondu,
    title: "Full Stack Developer",
    company: "Yondu Inc.",
    years: "2022 - 2023",
    type: "Full-time",
  },
  {
    image: innov,
    title: "Front-end Developer",
    company: "Innovations Groups",
    years: "2022",
    type: "Full-time",
  },
];

const works2: WorkData[] = [
  {
    image: qx,
    title: "Software Engineer",
    company: "Qstrike Innovations Phils OPC",
    years: "2019 - 2022",
    type: "Full-time",
  },
  {
    image: starfish,
    title: "Web Developer",
    company: "Starfish Web Design Philippines",
    years: "6 Months - 2021",
    type: "Part-time",
  },
  {
    image: gocloud,
    title: "Full Stack Developer",
    company: "GOCLOUD Inc.",
    years: "2018 - 2019",
    type: "Full-time",
  },
];

const Work = () => {
  const renderWorks = (work: WorkData, index: number) => {
    return (
      <div className="flex items-center gap-1" key={index}>
        <figure className="rounded-3xl hover:scale-105 transition w-48">
          <img src={work.image} alt={work.company} />
        </figure>
        <div className="divider lg:divider-horizontal" />
        <div className="hover:scale-105 transition">
          <div className="text-lg font-bold tracking-wide leading-8">
            {work.title}
          </div>
          <div className="text-sm font-normal tracking-wide leading-8">
            at {work.company}
          </div>
          <div className="text-xs font-normal tracking-wide leading-8">
            {work.years} ~ {work.type}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center mt-32">
      <div className="card w-[76rem] bg-base-300/80 shadow-xl">
        <div className="card-body m-10">
          <h2 className="card-title text-3xl">
            Work History
            <svg
              className="h-8 w-8 stroke-current"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 15a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
              <path d="M6 3a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
              <path d="M13 6h3a2 2 0 0 1 2 2v7" />
              <path d="M6 9v12" />
            </svg>
          </h2>
          <div className="flex gap-12">
            <div className="mt-20 flex flex-col gap-10">
              {works.map(renderWorks)}
            </div>
            <div className="mt-20 flex flex-col gap-10">
              {works2.map(renderWorks)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
