import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { resourceData } from "../../data/data";

interface ListToolsData {
  id: string;
  categoryId: string;
  createdAt: string;
  description: string;
  isFavourited: boolean;
  link: string;
  upvoteCount: number;
  resourceName: string;
  image: string;
}

interface ToolsProp {
  activeMenu: string;
}

const shuffleArray = (data: ListToolsData[]) => {
  return data.sort(() => Math.random() - 0.5);
};

const Tools = (props: ToolsProp) => {
  const { activeMenu } = props;

  const [tools, setTools] = useState<ListToolsData[]>(
    shuffleArray(resourceData)
  );

  useEffect(() => {
    if (activeMenu !== "all") {
      const data = resourceData.filter(
        (tool) => tool.categoryId === activeMenu
      );
      setTools(data);
      return;
    }

    setTools(resourceData);
  }, [activeMenu]);

  const truncateText = (text: string) => {
    if (text.length > 45) {
      return text.substring(0, 45) + "...";
    } else {
      return text;
    }
  };

  const renderTools = (tool: ListToolsData, index: number) => {
    const { resourceName, description, link, image } = tool;
    return (
      <Link to={link} target="_blank" key={index}>
        <div className="card card-side bg-base-200 shadow-lg max-w-[23rem] min-w-[23rem] h-[9rem] hover:scale-105 transition cursor-pointer">
          <figure className="max-w-[7rem] p-2 pl-4">
            <img className="rounded-lg" src={image} alt="Inspect" />
          </figure>
          <div className="card-body ">
            <h2 className="card-title text-xl">{resourceName}</h2>
            <p className="text-xs max-w-[20rem]">{truncateText(description)}</p>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className="flex flex-wrap gap-5 w-[90rem] max-h-[15rem]">
      {tools.map(renderTools)}
    </div>
  );
};

export default Tools;
