import { useEffect } from "react";

const Hero = () => {
  useEffect(() => {
    initBubble();
  }, []);

  const initBubble = () => {
    let interBubble = document.querySelector(".interactive")!;

    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    const moveMouse = (bubble: any) => {
      curX += (tgX - curX) / 20;
      curY += (tgY - curY) / 20;
      bubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(
        curY
      )}px)`;
      requestAnimationFrame(() => {
        moveMouse(interBubble);
      });
    };

    window.addEventListener("mousemove", (event) => {
      tgX = event.clientX;
      tgY = event.clientY;
    });

    moveMouse(interBubble);
  };

  return (
    <div className="hero min-h-screen" style={{ marginTop: "-7rem" }}>
      <div className="portfolio-background hero-overlay min-h-screen">
        <div className="gradient-bg">
          <svg xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                  result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
          </svg>
          <div className="gradients-container">
            <div className="circle-1"></div>
            <div className="circle-2"></div>
            <div className="circle-3"></div>
            <div className="circle-4"></div>
            <div className="circle-5"></div>
            <div className="interactive"></div>
          </div>
        </div>
      </div>
      <div className="hero-content text-center">
        <div className="max-w-5xl">
          <h1 className="text-6xl font-bold tracking-wide dark:text-white pb-10">
            Hi, I'm Emman!
          </h1>
          <h1 className="text-6xl font-bold tracking-wide dark:text-white">
            I <span className="text-[#f5dd55]">build</span> things on the{" "}
            <span className="text-[#179eca]/90">web</span>.
          </h1>
          <p className="py-6 tracking-wide leading-8 text-xl font-normal dark:text-white">
            I'm a Senior Front-end Developer and content creator based in the
            Philippines.
          </p>
          {/* <button className="btn btn-ghost border border-current dark:text-white px-5 mt-16">
            Let's Go
            <svg
              className="h-5 w-5 stroke-current fill-none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 5v14" />
              <path d="m19 12-7 7-7-7" />
            </svg>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
