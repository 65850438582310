const FESkills: string[] = [
  "ReactJs",
  "Hooks",
  "Redux",
  "Mobx",
  "TypeScript",
  "JavaScript",
  "VueJs",
  "NuxtJs",
  "Node",
  "MUI",
  "Tailwind",
  "Ajax",
  "CSS",
  "HTML",
  "JQuery",
  "Mobile Responsive",
  "Git",
];

const BESkills: string[] = [
  "Laravel",
  "PHP",
  "Express",
  "Node",
  "Laravel Lumen",
  "CodeIgniter",
  "Docker",
  "DBMS",
  "AWS",
  "Mysql",
  "MongoDB",
  "Git",
];

const Skills = () => {
  const renderSkills = (skill: string, index: number) => {
    return (
      <button
        key={index}
        className={`btn btn-ghost border border-current text-base font-light tracking-wide hover:scale-105`}
      >
        {skill}
      </button>
    );
  };

  return (
    <div className="flex justify-center mt-28">
      <div className="card w-[75rem] bg-base-100">
        <div className="card-body m-10">
          <h2 className="card-title text-3xl">
            Featured Skills
            <svg
              className="h-8 w-8 stroke-current fill-current"
              stroke="#a18787"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
            </svg>
          </h2>
          <div className="mt-5">
            <div className="flex flex-col gap-10">
              <div>
                <h2 className="text-2xl tracking-wide pb-2 text-center">
                  Frontend
                </h2>
                <div className="flex gap-3 flex-wrap justify-center">
                  {FESkills.map(renderSkills)}
                </div>
              </div>
              <div>
                <h2 className="text-2xl tracking-wide pb-2 text-center">
                  Backend
                </h2>
                <div className="flex gap-3 flex-wrap justify-center">
                  {BESkills.map(renderSkills)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
