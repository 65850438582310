import { HashRouter } from "react-router-dom";
import "./App.scss";
import WebRoutes from "./components/routes/WebRoutes";

const App = () => {
  return (
    <HashRouter>
      <WebRoutes />
    </HashRouter>
  );
};

export default App;
