import mylogo from "../assets/img/my-logo-light.png";
import blush from "../assets/res/blush.svg";
import checklist from "../assets/res/checklist.png";
import dribble from "../assets/res/dribble.png";
import feathericons from "../assets/res/feathericons.png";
import figmaResource from "../assets/res/figma-resource.png";
import figma from "../assets/res/figma.png";
import googleFont from "../assets/res/googleFont.png";
import heydesigner from "../assets/res/heydesigner.png";
import iconscout from "../assets/res/iconscout.png";
import lawsofux from "../assets/res/lawsofux.png";
import mobbin from "../assets/res/mobbin.png";
import muzli from "../assets/res/muzli.png";
import openpeeps from "../assets/res/openpeeps.png";
import sketch from "../assets/res/sketch.png";
import uisources from "../assets/res/uisources.png";
import unsplash from "../assets/res/unsplash.png";
import webflow from "../assets/res/webflow.png";

export const categories = [
  {
    id: "clqpwckl40006vb3bo7x08570",
    name: "Icons",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwceop0002vb3bn3073ugx",
    name: "Color",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwbsp50000vb3b1vghb920",
    name: "Accessibility",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwbyzb0001vb3bto2y9dy2",
    name: "Books",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwckl4000bvb3b2bhmajtx",
    name: "Mockup Tools",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwckl30003vb3b0wts8kvd",
    name: "Design News",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwckl30004vb3bi0vrqj22",
    name: "Design Systems",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwckl30005vb3bd3pajldj",
    name: "Design Tools",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },

  {
    id: "clqpwckl40007vb3b24apop4l",
    name: "Illustrations",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwckl40008vb3ba41vsbz9",
    name: "Inspiration",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwckl40009vb3b31fslsa9",
    name: "Jobs",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwckl4000avb3b7me9u31c",
    name: "Learn Design",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },

  {
    id: "clqpwckl4000cvb3b6ng3as0u",
    name: "Podcasts",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwetea000hvb3bhuy19oug",
    name: "Prototyping Tools",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwetea000fvb3bxu5katf7",
    name: "Stock Photos",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwetea000gvb3bxlwiq1ez",
    name: "Resources",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqqy1ssm0000vbn8g0dx05ls",
    name: "Typography",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
  {
    id: "clqpwete9000evb3bucmzdlxb",
    name: "Wireframing",
    icon: null,
    createdAt: "2023-12-29T22:14:29.877Z",
  },
];

export const resourceData = [
  {
    id: "clqre73b3005nvboe9ty5orxx",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:36:59.006Z",
    description: "Design and collaborate all in the browser",
    isFavourited: false,
    link: "https://www.figma.com/?ref=inspectresource.com",
    upvoteCount: 393,
    resourceName: "Figma",
    image: figma,
  },
  {
    id: "clqre6vlm004vvboeslvyi1gw",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:36:49.018Z",
    description: "Online community for showcasing user artwork",
    isFavourited: false,
    link: "https://dribbble.com/?ref=inspectresource.com",
    upvoteCount: 208,
    resourceName: "Dribbble",
    image: dribble,
  },
  {
    id: "clqre8fj200afvboesada64oq",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:01.502Z",
    description: "All the design inspiration you need. Served fresh.",
    isFavourited: false,
    link: "https://muz.li/?ref=inspectresource.com",
    upvoteCount: 150,
    resourceName: "Muzli",
    image: muzli,
  },
  {
    id: "clqrea0a300frvboe6zs3tdnp",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:39:15.050Z",
    description: "Learn web design with free video courses and tutorials",
    isFavourited: false,
    link: "https://university.webflow.com/videos?ref=inspectresource.com",
    upvoteCount: 140,
    resourceName: "Webflow",
    image: webflow,
  },
  {
    id: "clqre8ezj00advboeyyka7vvt",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:00.799Z",
    description: "Latest mobile design patterns",
    isFavourited: false,
    link: "https://mobbin.design/?ref=inspectresource.com",
    upvoteCount: 109,
    resourceName: "Mobbin",
    image: mobbin,
  },
  {
    id: "clqre63d30023vboevdjwat59",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:12.423Z",
    description: "A collection of the best UX and UI practices.",
    isFavourited: false,
    link: "https://www.checklist.design/?ref=inspectresource.com",
    upvoteCount: 85,
    resourceName: "Checklist.design",
    image: checklist,
  },
  {
    id: "clqre9sml00f1vboes9rd7i86",
    categoryId: "clqpwetea000fvb3bxu5katf7",
    createdAt: "2023-12-30T01:39:05.133Z",
    description: "The internet’s source of freely usable images",
    isFavourited: false,
    link: "https://unsplash.com/?ref=inspectresource.com",
    upvoteCount: 81,
    resourceName: "Unsplash",
    image: unsplash,
  },
  {
    id: "clqre7n3n007lvboeoyc9jlfj",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:24.660Z",
    description:
      "Daily curated newsfeed for product people, UXers, PMs and frontend developers.",
    isFavourited: false,
    link: "https://heydesigner.com/?ref=inspectresource.com",
    upvoteCount: 80,
    resourceName: "Hey Designer",
    image: heydesigner,
  },
  {
    id: "clqre9dg800dlvboe9gnf98xc",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:38:45.464Z",
    description:
      "Sketch is the most popular design tool for UX/UI designers 💎",
    isFavourited: false,
    link: "https://www.sketchapp.com/?ref=inspectresource.com",
    upvoteCount: 73,
    resourceName: "Sketch",
    image: sketch,
  },
  {
    id: "clqre84vp009dvboeflo8pz7l",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:47.701Z",
    description:
      "Researched principles and laws that help guide design process",
    isFavourited: false,
    link: "https://lawsofux.com/?ref=inspectresource.com",
    upvoteCount: 67,
    resourceName: "Laws of UX",
    image: lawsofux,
  },
  {
    id: "clqre8l8400axvboedykekwxe",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:38:08.884Z",
    description: "A hand-drawn people illustration library",
    isFavourited: false,
    link: "https://www.openpeeps.com?ref=inspectresource.com",
    upvoteCount: 60,
    resourceName: "Open Peeps",
    image: openpeeps,
  },
  {
    id: "clqre9jul00e7vboeik63u3ec",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:38:53.757Z",
    description: "Over 30,000 something icons",
    isFavourited: false,
    link: "https://transactions.sendowl.com/stores/13699/190251",
    upvoteCount: 55,
    resourceName: "Streamline",
    image: mylogo,
  },
  {
    id: "clqre9pxc00etvboed1601kfw",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:39:01.633Z",
    description: "Interactions from the best designed and top grossing apps",
    isFavourited: false,
    link: "https://gumroad.com/a/733443187",
    upvoteCount: 49,
    resourceName: "UI Sources",
    image: uisources,
  },
  {
    id: "clqre74p8005rvboe00j123a9",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:37:00.812Z",
    description: "Resources made with love by the Figma community",
    isFavourited: false,
    link: "https://www.notion.so/Remote-Design-Resources-from-the-Community-e2af55fa7ace484bbe66d98ba3fd2020",
    upvoteCount: 45,
    resourceName: "Figma Resources",
    image: figmaResource,
  },
  {
    id: "clqre7ilo0075vboeck89ri1f",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:37:18.828Z",
    description:
      "Making the web more beautiful, fast, and open through great typography",
    isFavourited: false,
    link: "https://fonts.google.com/?ref=inspectresource.com",
    upvoteCount: 42,
    resourceName: "Google Fonts",
    image: googleFont,
  },
  {
    id: "clqre7t1x0087vboefziszy0g",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:32.374Z",
    description: "High-quality Icons, illustrations and stock photos",
    isFavourited: false,
    link: "https://iconscout.com/?ref=inspectresource.com",
    upvoteCount: 42,
    resourceName: "Iconscout",
    image: iconscout,
  },
  {
    id: "clqre726s005jvboexqa1on84",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:36:57.556Z",
    description: "Simply beautiful open source icons",
    isFavourited: false,
    link: "https://feathericons.com/?ref=inspectresource.com",
    upvoteCount: 42,
    resourceName: "Feather Icons",
    image: feathericons,
  },
  {
    id: "clqre5w10001fvboem2qcre55",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:36:02.916Z",
    description: "Illustrations for everyone",
    isFavourited: false,
    link: "https://blush.design?ref=inspectresource.com",
    upvoteCount: 40,
    resourceName: "Blush",
    image: blush,
  },
  {
    id: "clqre9pd900ervboejfzoyugd",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:39:00.909Z",
    description: "The best UI design inspiration, every day",
    isFavourited: false,
    link: "https://uimovement.com/?ref=inspectresource.com",
    upvoteCount: 40,
    resourceName: "UI Movement",
    image: mylogo,
  },
  {
    id: "clqre5muq000jvboe5xy3103r",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:35:51.026Z",
    description: "An all-in-one design and prototyping app.",
    isFavourited: false,
    link: "https://www.adobe.com/uk/products/xd.html?ref=inspectresource.com",
    upvoteCount: 39,
    resourceName: "Adobe XD",
    image: mylogo,
  },
  {
    id: "clqre7m01007hvboezmdp75qm",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:37:23.233Z",
    description: "Curated colors in context",
    isFavourited: false,
    link: "https://www.happyhues.co/",
    upvoteCount: 38,
    resourceName: "Happy Hues",
    image: mylogo,
  },
  {
    id: "clqre7k9x007bvboexbx4ug1m",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:20.997Z",
    description: "Growth & UX case studies in a comic book format",
    isFavourited: false,
    link: "https://growth.design/case-studies?ref=inspectresource.com",
    upvoteCount: 37,
    resourceName: "Growth Design",
    image: mylogo,
  },
  {
    id: "clqre7pu5007vvboekeu5y34l",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:37:28.206Z",
    description: "Mix-&-match illustrations of people with a design library",
    isFavourited: false,
    link: "https://www.humaaans.com/?ref=inspectresource.com",
    upvoteCount: 36,
    resourceName: "Humaaans",
    image: mylogo,
  },
  {
    id: "clqre6mqm0041vboe5yl2qvw6",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:36:37.534Z",
    description: "An open-source checklist to help build design systems",
    isFavourited: false,
    link: "https://designsystemchecklist.com?ref=inspectresource.coms",
    upvoteCount: 36,
    resourceName: "Design Systems",
    image: mylogo,
  },
  {
    id: "clqre7rg40081vboeo9tzz5v8",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:30.292Z",
    description: "Marketplace for icons",
    isFavourited: false,
    link: "https://www.iconfinder.com/",
    upvoteCount: 30,
    resourceName: "Iconfinder",
    image: mylogo,
  },
  {
    id: "clqre6hbe003hvboet6r9tm53",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:36:30.392Z",
    description: "The best online design events",
    isFavourited: false,
    link: "https://www.designcalendar.io/remote/events?ref=inspectresource.com",
    upvoteCount: 30,
    resourceName: "Design Calendar",
    image: mylogo,
  },
  {
    id: "clqre5qpu000xvboel9he2o7a",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:35:56.035Z",
    description:
      "Learn about developing for Apple platforms with video presentations by Apple experts.",
    isFavourited: false,
    link: "https://developer.apple.com/videos/design/?ref=inspectresource.com",
    upvoteCount: 28,
    resourceName: "Apple Design Videos",
    image: mylogo,
  },
  {
    id: "clqre85i6009fvboe9xpu1z3j",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:37:48.407Z",
    description:
      "Weekly podcast about design, tech, programming and everything else",
    isFavourited: false,
    link: "https://layout.fm/?ref=inspectresource.com",
    upvoteCount: 28,
    resourceName: "Layout",
    image: mylogo,
  },
  {
    id: "clqre6x8r0051vboe62w0ezf2",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:51.147Z",
    description: "Collection of colour combinations curated from the web",
    isFavourited: false,
    link: "https://duo.alexpate.uk?ref=inspectresource.com",
    upvoteCount: 28,
    resourceName: "Duo",
    image: mylogo,
  },
  {
    id: "clqre6b94002vvboeqrq35g8i",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:36:22.648Z",
    description: "108 customizable illustrations",
    isFavourited: false,
    link: "https://control.rocks/?ref=inspectresource.com",
    upvoteCount: 27,
    resourceName: "Control",
    image: mylogo,
  },
  {
    id: "clqre94nh00cpvboe8burz8t4",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:38:34.061Z",
    description: "Open-source neutral-style system symbols",
    isFavourited: false,
    link: "https://remixicon.com/?ref=inspectresource.com",
    upvoteCount: 27,
    resourceName: "Remix",
    image: mylogo,
  },
  {
    id: "clqre7zty008vvboecuuv5gka",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:37:41.158Z",
    description: "Turn screens into interactive prototypes",
    isFavourited: false,
    link: "https://www.invisionapp.com/?ref=inspectresource.com",
    upvoteCount: 25,
    resourceName: "InVision Studio",
    image: mylogo,
  },
  {
    id: "clqre6g4d003dvboev4amtz4f",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:36:28.957Z",
    description: "Insights from the world’s most renowned design leaders,",
    isFavourited: false,
    link: "https://www.designbetter.co/podcast",
    upvoteCount: 25,
    resourceName: "Design Better Podcast",
    image: mylogo,
  },
  {
    id: "clqre9vr700fbvboe1effyl8t",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:39:09.187Z",
    description: "Curated stories on UX, Visual & Product Design",
    isFavourited: false,
    link: "https://uxdesign.cc/?ref=inspectresource.com",
    upvoteCount: 24,
    resourceName: "UX Collective",
    image: mylogo,
  },
  {
    id: "clqre7nn2007nvboek1rz75fh",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:37:25.358Z",
    description: "How products are made to addictive",
    isFavourited: false,
    link: "https://www.amazon.co.uk/Hooked-How-Build-Habit-Forming-Products/dp/0241184835/ref=as_li_ss_tl?_encoding=UTF8&qid=1586802810&sr=8-1&linkCode=ll1&tag=kylerdesign-21&linkId=725464c384642c38e0f75c5ef01d78a9&language=en_GB",
    upvoteCount: 24,
    resourceName: "Hooked",
    image: mylogo,
  },
  {
    id: "clqre6yxx0057vboeug3njjjz",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:36:53.350Z",
    description: "480 delightful icons available for free.",
    isFavourited: false,
    link: "https://akveo.github.io/eva-icons?ref=inspectresource.com",
    upvoteCount: 24,
    resourceName: "Eva",
    image: mylogo,
  },
  {
    id: "clqre7qde007xvboe75wxv4e4",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:28.898Z",
    description: "Tips for designing ethically humane digital products",
    isFavourited: false,
    link: "https://humanebydesign.com/?ref=inspectresource.com",
    upvoteCount: 24,
    resourceName: "Humane",
    image: mylogo,
  },
  {
    id: "clqrea0tg00ftvboebmin011o",
    categoryId: "clqpwete9000evb3bucmzdlxb",
    createdAt: "2023-12-30T01:39:15.748Z",
    description: "Collaborate visually. Fast and delightful.",
    isFavourited: false,
    link: "https://whimsical.co?ref=inspectresource.com",
    upvoteCount: 23,
    resourceName: "Whimsical",
    image: mylogo,
  },
  {
    id: "clqre9rfz00exvboefu951yrt",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:39:03.599Z",
    description: "Open-source illustrations for any idea",
    isFavourited: false,
    link: "https://undraw.co/illustrations?ref=inspectresource.com",
    upvoteCount: 22,
    resourceName: "Undraw",
    image: mylogo,
  },
  {
    id: "clqre5pmw000tvboeeca5v156",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:35:54.632Z",
    description: "Animated product mockups with videos and animated GIFs",
    isFavourited: false,
    link: "https://animockup.com/?ref=inspectresource.com",
    upvoteCount: 20,
    resourceName: "Animockup",
    image: mylogo,
  },
  {
    id: "clqre8s3v00bjvboet4mj5df6",
    categoryId: "clqpwetea000fvb3bxu5katf7",
    createdAt: "2023-12-30T01:38:17.803Z",
    description: "Stock photos & videos shared by talented creators.",
    isFavourited: false,
    link: "https://www.pexels.com/?ref=inspectresource.com",
    upvoteCount: 20,
    resourceName: "Pexels",
    image: mylogo,
  },
  {
    id: "clqre8ko300avvboehc5y0x6y",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:38:08.163Z",
    description: "A Free Set of Sketchy Illustrations",
    isFavourited: false,
    link: "https://opendoodles.com?ref=inspectresource.com",
    upvoteCount: 19,
    resourceName: "Open Doodles",
    image: mylogo,
  },
  {
    id: "clqre6v28004tvboe91kwgwh8",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:36:48.320Z",
    description: "Beautiful illustrations for you to use on your next website",
    isFavourited: false,
    link: "https://www.drawkit.io/?ref=inspectresource.com",
    upvoteCount: 19,
    resourceName: "DrawKit",
    image: mylogo,
  },
  {
    id: "clqre9mm100ehvboem0kzyqip",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:38:57.338Z",
    description:
      "Helps designers choose the perfect font combination for their next projecct",
    isFavourited: false,
    link: "https://www.typewolf.com/?ref=inspectresource.com",
    upvoteCount: 18,
    resourceName: "Typewolf",
    image: mylogo,
  },
  {
    id: "clqre6hw5003jvboemtj2x9wt",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:31.253Z",
    description: "Complete courses about the best tools and design systems.",
    isFavourited: false,
    link: "https://designcode.io/?ref=inspectresource.com",
    upvoteCount: 18,
    resourceName: "Design+Code",
    image: mylogo,
  },
  {
    id: "clqre7j5g0077vboekgcutv88",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:19.541Z",
    description: "Designing Android applications.",
    isFavourited: false,
    link: "https://material.io/guidelines?ref=inspectresource.com",
    upvoteCount: 18,
    resourceName: "Google Material Design",
    image: mylogo,
  },
  {
    id: "clqre6uj1004rvboe3bh2hdn5",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:36:47.629Z",
    description: "A Common Sense Approach to Web Usability",
    isFavourited: false,
    link: "https://amzn.to/2xT9qIP",
    upvoteCount: 18,
    resourceName: "Don't Make Me Think",
    image: mylogo,
  },
  {
    id: "clqre5ojs000pvboe6a58xh5d",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:35:53.224Z",
    description: "Find amazing designers for hire",
    isFavourited: false,
    link: "https://www.adplist.org?ref=inspectresource.com",
    upvoteCount: 17,
    resourceName: "Amazing Design People",
    image: mylogo,
  },
  {
    id: "clqre5lsa000fvboe7dk3go8n",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:35:49.642Z",
    description: "Browse thousands of color combinations",
    isFavourited: false,
    link: "https://color.adobe.com/explore",
    upvoteCount: 16,
    resourceName: "Adobe Colour",
    image: mylogo,
  },
  {
    id: "clqre9jav00e5vboe6jw17inw",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:38:53.047Z",
    description: "Color-blind simulator and contrast checker",
    isFavourited: false,
    link: "https://www.getstark.co?ref=inspectresource.com",
    upvoteCount: 16,
    resourceName: "Stark",
    image: mylogo,
  },
  {
    id: "clqre6nud0045vboeqvnnb7z2",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:38.966Z",
    description:
      "The essential guide to the best design practices from top design experts",
    isFavourited: false,
    link: "https://www.designbetter.co?ref=inspectresource.com",
    upvoteCount: 16,
    resourceName: "DesignBetter.co",
    image: mylogo,
  },
  {
    id: "clqre72r1005lvboeu5ln1dm0",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:58.285Z",
    description:
      "Product designer focusing on learnings and experiences of being a designer",
    isFavourited: false,
    link: "https://www.youtube.com/channel/UCWUGGwfTfJ0-2jUS3dZqOJA/videos",
    upvoteCount: 16,
    resourceName: "Femke",
    image: mylogo,
  },
  {
    id: "clqre8qfd00bdvboe0eovfl0a",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:38:15.625Z",
    description: "All the sizes for international paper formats",
    isFavourited: false,
    link: "https://papersizes.io?ref=designeresourc.es",
    upvoteCount: 15,
    resourceName: "Paper Sizes",
    image: mylogo,
  },
  {
    id: "clqre8upv00brvboe5ns62sa2",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:38:21.187Z",
    description: "Version Control for Designers",
    isFavourited: false,
    link: "https://plantapp.io?ref=inspectresource.com",
    upvoteCount: 14,
    resourceName: "Plant",
    image: mylogo,
  },
  {
    id: "clqre96y700cxvboe43lkkp8f",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:38:37.040Z",
    description: "A weekly podcast profiling the stories of Filipino Designers",
    isFavourited: false,
    link: "https://rootspodcast.design?ref=inspectresource.com",
    upvoteCount: 14,
    resourceName: "Roots",
    image: mylogo,
  },
  {
    id: "clqre8xwa00c1vboeu6todpof",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:38:25.306Z",
    description:
      "Principle makes it easy to design animated and interactive user interfaces",
    isFavourited: false,
    link: "https://principleformac.com?ref=inspectresource.com",
    upvoteCount: 14,
    resourceName: "Principle",
    image: mylogo,
  },
  {
    id: "clqre8ier00apvboewbc0id5v",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:38:05.235Z",
    description: "Over 2 Million curated icons, created by a global community",
    isFavourited: false,
    link: "https://thenounproject.com?ref=inspectresource.com",
    upvoteCount: 14,
    resourceName: "Noun Project",
    image: mylogo,
  },
  {
    id: "clqre82ma0095vboee7ddsyps",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:37:44.770Z",
    description: "Uses AI to learn which colors you like and creates palettes",
    isFavourited: false,
    link: "http://khroma.co?ref=designresources",
    upvoteCount: 14,
    resourceName: "Khroma",
    image: mylogo,
  },
  {
    id: "clqre60qh001vvboe3atv2357",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:36:09.017Z",
    description: "IBM's design system",
    isFavourited: false,
    link: "https://www.carbondesignsystem.com/?ref=inspectresource.com",
    upvoteCount: 14,
    resourceName: "Carbon Design System",
    image: mylogo,
  },
  {
    id: "clqre88w5009rvboeptg4bwmq",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:37:52.901Z",
    description: "Easily add high-quality animation to any native app",
    isFavourited: false,
    link: "https://airbnb.design/lottie/?ref=inspectresource.com",
    upvoteCount: 13,
    resourceName: "Lottie",
    image: mylogo,
  },
  {
    id: "clqre7vbp008fvboe25wand11",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:37:35.317Z",
    description: "A large set of open-source illustrations library",
    isFavourited: false,
    link: "https://illlustrations.co?ref=inspectresource.com",
    upvoteCount: 13,
    resourceName: "Illlustrations",
    image: mylogo,
  },
  {
    id: "clqre9z5l00fnvboewibu2ay6",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:39:13.593Z",
    description: "Sources and selects the top creative jobs",
    isFavourited: false,
    link: "http://wethemakers.club/jobs?ref=inspectresource.com",
    upvoteCount: 13,
    resourceName: "We The Makers Club",
    image: mylogo,
  },
  {
    id: "clqre5xeo001jvboe6spwscuh",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:36:04.704Z",
    description: "Design Management and Leadership Jobs",
    isFavourited: false,
    link: "https://boooom.co/?ref=inspectresource.com",
    upvoteCount: 13,
    resourceName: "Boooom",
    image: mylogo,
  },
  {
    id: "clqre629t001zvboe1tho8m4n",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:36:11.009Z",
    description: "A set of 16 handcrafted illustrations",
    isFavourited: false,
    link: "https://www.karthiksrinivas.in/charco?ref=inspectresource.com",
    upvoteCount: 12,
    resourceName: "Charco",
    image: mylogo,
  },
  {
    id: "clqre7gwm006zvboea68u3xee",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:37:16.630Z",
    description: "Discover the best design books",
    isFavourited: false,
    link: "https://www.goodbooks.io/categories/design?ref=inspectresource.com",
    upvoteCount: 12,
    resourceName: "Good Books",
    image: mylogo,
  },
  {
    id: "clqre5shm0013vboe5ykv4g4i",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:35:58.331Z",
    description: "Mix and match avatars illustration library",
    isFavourited: false,
    link: "https://avataaars.com/?ref=inspectresource.com",
    upvoteCount: 12,
    resourceName: "Avataaars",
    image: mylogo,
  },
  {
    id: "clqre5t2t0015vboe0wmxuv16",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:35:59.093Z",
    description: "Awards for design, creativity and innovation",
    isFavourited: false,
    link: "http://www.awwwards.com?ref=inspectresource.com",
    upvoteCount: 12,
    resourceName: "Awwwards",
    image: mylogo,
  },
  {
    id: "clqre8gms00ajvboexx9p1hom",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:38:02.932Z",
    description:
      "A conversation on everything related to digital product design",
    isFavourited: false,
    link: "https://anchor.fm/new-layer?ref=inspectresource.com",
    upvoteCount: 12,
    resourceName: "New Layer",
    image: mylogo,
  },
  {
    id: "clqre6sbh004jvboe4sdxwo6e",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:44.765Z",
    description: "Learn how to design more mindful, impactful digital products",
    isFavourited: false,
    link: "https://designerup.co?ref=inspectresource.com",
    upvoteCount: 12,
    resourceName: "DesignerUp",
    image: mylogo,
  },
  {
    id: "clqre6jhx003pvboebhvg67bv",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:36:33.333Z",
    description:
      "Two women in tech talk about balancing side projects on top of a full time job.",
    isFavourited: false,
    link: "https://www.designlife.fm/?ref=inspectresource.com",
    upvoteCount: 11,
    resourceName: "Design Life",
    image: mylogo,
  },
  {
    id: "clqre6ifh003lvboe6upxndwk",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:36:31.949Z",
    description: "A weekly conversation about design process and culture",
    isFavourited: false,
    link: "https://spec.fm/podcasts/design-details?ref=inspectresource.com",
    upvoteCount: 11,
    resourceName: "Design Details",
    image: mylogo,
  },
  {
    id: "clqre6781002hvboe63o290ym",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:36:17.426Z",
    description: "Build accessible colour combinations",
    isFavourited: false,
    link: "http://colorsafe.co?ref=inspectresource.com",
    upvoteCount: 11,
    resourceName: "Color Safe",
    image: mylogo,
  },
  {
    id: "clqre713m005fvboebbeg0hgs",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:36:56.146Z",
    description: "A collection of tools and resources from Facebook Design.",
    isFavourited: false,
    link: "https://facebook.design/?ref=inspectresource.com",
    upvoteCount: 11,
    resourceName: "Facebook",
    image: mylogo,
  },
  {
    id: "clqre8zra00c7vboe3vp5qvsn",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:38:27.718Z",
    description: "A curation of the best new products, every day.",
    isFavourited: false,
    link: "https://www.producthunt.com/?ref=inspectresource.com",
    upvoteCount: 11,
    resourceName: "Product Hunt",
    image: mylogo,
  },
  {
    id: "clqre61q9001xvboentkf3t9n",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:10.305Z",
    description: "Curated gallery of the best UI/UX design case studies.",
    isFavourited: false,
    link: "https://www.casestudy.club/?ref=inspectresource.com",
    upvoteCount: 11,
    resourceName: "Case Study Club",
    image: mylogo,
  },
  {
    id: "clqre5tpp0017vboeot9e1cos",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:35:59.823Z",
    description: "Find inspiration from other designers",
    isFavourited: false,
    link: "https://www.behance.net/?ref=inspectresource.com",
    upvoteCount: 11,
    resourceName: "Behance",
    image: mylogo,
  },
  {
    id: "clqre5r98000zvboeton8vxdl",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:35:56.732Z",
    description: "Designing iOS applications.",
    isFavourited: false,
    link: "https://developer.apple.com/ios/human-interface-guidelines/overview/themes/",
    upvoteCount: 11,
    resourceName: "Apple HIG",
    image: mylogo,
  },
  {
    id: "clqre7hiw0071vboe9ywnmgl0",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:17.337Z",
    description: "Writings of designers, writers, and developers at Google.",
    isFavourited: false,
    link: "https://design.google/?ref=inspectresource.com",
    upvoteCount: 11,
    resourceName: "Google",
    image: mylogo,
  },
  {
    id: "clqre8hve00anvboe6trd9384",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:38:04.538Z",
    description: "World Leaders in Research-Based User Experience",
    isFavourited: false,
    link: "https://www.nngroup.com?ref=inspectresource.com",
    upvoteCount: 10,
    resourceName: "Nielsen Norman Group",
    image: mylogo,
  },
  {
    id: "clqre786j0063vboezfoy4jkt",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:05.323Z",
    description:
      "Flux is the personal vlog of Ran Segall, a designer and entrepreneur",
    isFavourited: false,
    link: "https://www.youtube.com/channel/UCN7dywl5wDxTu1RM3eJ_h9Q/featured?ref=inspectresource.com",
    upvoteCount: 10,
    resourceName: "Flux",
    image: mylogo,
  },
  {
    id: "clqre6w5d004xvboe0rwcvc7u",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:36:49.729Z",
    description: "Connect with designers and jobs worldwide",
    isFavourited: false,
    link: "https://dribbble.com/jobs",
    upvoteCount: 10,
    resourceName: "Dribbble Jobs",
    image: mylogo,
  },
  {
    id: "clqre8mbn00b1vboeh6jnkzcv",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:38:10.307Z",
    description: "Free Vector Illustrations to Class up Your Project",
    isFavourited: false,
    link: "https://icons8.com/ouch?ref=inspectresource.com",
    upvoteCount: 10,
    resourceName: "Ouch!",
    image: mylogo,
  },
  {
    id: "clqre7c2f006hvboef4uvgoi0",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:37:10.358Z",
    description: "A powerful product that blends design and development",
    isFavourited: false,
    link: "https://framer.com/?ref=inspectresource.com",
    upvoteCount: 10,
    resourceName: "Framer",
    image: mylogo,
  },
  {
    id: "clqre65lg002bvboezjgyep0a",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:15.215Z",
    description: "Free and open platform for color inspiration",
    isFavourited: false,
    link: "https://colorhunt.co/?ref=inspectresource.com",
    upvoteCount: 10,
    resourceName: "Color Hunt",
    image: mylogo,
  },
  {
    id: "clqre69j0002pvboezr5xj1l9",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:20.412Z",
    description: "All in one resource for finding colors",
    isFavourited: false,
    link: "https://colorsinspo.com?ref=inspectresource.com",
    upvoteCount: 9,
    resourceName: "Colorsinspo",
    image: mylogo,
  },
  {
    id: "clqre8tyk00bpvboea2se3s6u",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:38:20.204Z",
    description: "Collaborative workspace for Figma users",
    isFavourited: false,
    link: "https://www.pixelic.io?ref=inspectresource.com",
    upvoteCount: 9,
    resourceName: "Pixelic",
    image: mylogo,
  },
  {
    id: "clqre9n6400ejvboef3cadpba",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:38:58.060Z",
    description: "Connect to different cultures through poster design",
    isFavourited: false,
    link: "https://www.typographicposters.com/?ref=inspectresource.com",
    upvoteCount: 9,
    resourceName: "Typo/graphic",
    image: mylogo,
  },
  {
    id: "clqre6u00004pvboegwr9vu76",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:36:46.945Z",
    description: "Device mockup generator for your screenshots",
    isFavourited: false,
    link: "https://dimmy.club/?ref=inspectresource.com",
    upvoteCount: 9,
    resourceName: "Dimmy.club",
    image: mylogo,
  },
  {
    id: "clqre5u9j0019vboep77quxi9",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:36:00.632Z",
    description: "Hand-picked website inspiration",
    isFavourited: false,
    link: "https://bestwebsite.gallery/?ref=inspectresource.com",
    upvoteCount: 9,
    resourceName: "Best Website Gallery",
    image: mylogo,
  },
  {
    id: "clqre8c7s00a3vboeeqcljlaj",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:57.208Z",
    description: "Latest news from Microsoft design",
    isFavourited: false,
    link: "https://www.microsoft.com/design/?ref=inspectresource.com",
    upvoteCount: 9,
    resourceName: "Microsoft",
    image: mylogo,
  },
  {
    id: "clqre5k5c0009vboe7q807rzi",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:35:47.520Z",
    description: "Absurd illustrations that make sense",
    isFavourited: false,
    link: "https://absurd.design/?ref=inspectresource.com",
    upvoteCount: 9,
    resourceName: "Absurd Design",
    image: mylogo,
  },
  {
    id: "clqre7er7006rvboee98mhky4",
    categoryId: "clqpwetea000fvb3bxu5katf7",
    createdAt: "2023-12-30T01:37:13.843Z",
    description: "Unique, worry-free model photos",
    isFavourited: false,
    link: "https://generated.photos/?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Generated Photos",
    image: mylogo,
  },
  {
    id: "clqre9wfj00fdvboeytkowaa5",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:39:10.064Z",
    description: "Hand picked list of the best user experience design links",
    isFavourited: false,
    link: "https://uxdesignweekly.com?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "UX Design Weekly",
    image: mylogo,
  },
  {
    id: "clqre9ipk00e3vboekp2jfqqj",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:38:52.280Z",
    description: "Adobe’s design system",
    isFavourited: false,
    link: "https://spectrum.adobe.com?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Spectrum by Adobe",
    image: mylogo,
  },
  {
    id: "clqre8v9900btvboeukjx7mla",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:38:21.885Z",
    description: "Tips on career development and design process",
    isFavourited: false,
    link: "https://askplaybook.com?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Playbook",
    image: mylogo,
  },
  {
    id: "clqrea4oq00g7vboef35mc6q7",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:39:20.762Z",
    description: "Handoff designs with specs, assets and code snippets",
    isFavourited: false,
    link: "https://zeplin.io/?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Zeplin",
    image: mylogo,
  },
  {
    id: "clqre6fkn003bvboenxte0deh",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:28.247Z",
    description: "Everything from design school, without design school",
    isFavourited: false,
    link: "https://www.degreeless.design/?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Degreeless.design",
    image: mylogo,
  },
  {
    id: "clqre861s009hvboe814pg9mu",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:49.217Z",
    description: "A curated list of mobile design resources",
    isFavourited: false,
    link: "https://learnmobile.design?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Learn Mobile Design",
    image: mylogo,
  },
  {
    id: "clqre80zh008zvboe38uz22qc",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:42.653Z",
    description: "Beautifully crafted open source icons",
    isFavourited: false,
    link: "https://ionicons.com/?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Ionicons",
    image: mylogo,
  },
  {
    id: "clqre6nax0043vboenxjs2vne",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:36:38.265Z",
    description: "A frequently updated collection of Design System examples",
    isFavourited: false,
    link: "https://designsystemsrepo.com?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Design Systems Repo",
    image: mylogo,
  },
  {
    id: "clqre6pja004bvboejrpd92hg",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:36:41.158Z",
    description:
      "A community for designers to share interesting links and events.",
    isFavourited: false,
    link: "https://www.designernews.co/?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Designer News",
    image: mylogo,
  },
  {
    id: "clqre7sj50085vboeiav72crn",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:31.697Z",
    description: "126,300 free icons",
    isFavourited: false,
    link: "https://icons8.com/icons?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Icons8",
    image: mylogo,
  },
  {
    id: "clqre6wom004zvboefhkfd5gh",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:36:50.423Z",
    description: "A collection of resources from Dropbox design team",
    isFavourited: false,
    link: "https://dropbox.design?ref=inspectresource.com",
    upvoteCount: 8,
    resourceName: "Dropbox Design",
    image: mylogo,
  },
  {
    id: "clqre78qf0065vboees5h1yny",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:06.039Z",
    description: "The web's most popular icon set and toolkit",
    isFavourited: false,
    link: "https://fontawesome.com/?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "Font Awesome",
    image: mylogo,
  },
  {
    id: "clqre6bs3002xvboe2ql5if4m",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:23.332Z",
    description: "Fast color schemes generator",
    isFavourited: false,
    link: "https://coolors.co?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "Coolors",
    image: mylogo,
  },
  {
    id: "clqre5hy20001vboedsq92ljo",
    categoryId: "clqpwckl40007vb3b24apop4l",
    createdAt: "2023-12-30T01:35:44.665Z",
    description: "Royalty free illustrations for 404 pages",
    isFavourited: false,
    link: "https://error404.fun/?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "404 Illustrations",
    image: mylogo,
  },
  {
    id: "clqre8bnv00a1vboeeanayq04",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:56.491Z",
    description: "Follow to get great stories about Design on Medium",
    isFavourited: false,
    link: "https://medium.com/topic/design?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "Medium",
    image: mylogo,
  },
  {
    id: "clqre8j3m00arvboe52zq3q84",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:06.130Z",
    description: "One page websites, templates and resources",
    isFavourited: false,
    link: "https://onepagelove.com/?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "One Page Love",
    image: mylogo,
  },
  {
    id: "clqre8qz000bfvboe8is5dx3n",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:38:16.332Z",
    description: "Fast, collaborative presentations for creative teams",
    isFavourited: false,
    link: "https://paste.bywetransfer.com?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "Paste",
    image: mylogo,
  },
  {
    id: "clqre8yg600c3vboefbxh8j4q",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:38:26.022Z",
    description: "A curated list of resources to learn product design",
    isFavourited: false,
    link: "https://productdisrupt.com/?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "Product Disrupt",
    image: mylogo,
  },
  {
    id: "clqre98kn00d3vboebqgadpcu",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:38:39.144Z",
    description: "Shortcuts for designers in one place Shortcuts 🚀",
    isFavourited: false,
    link: "https://shortcuts.design/?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "Shortcuts.design",
    image: mylogo,
  },
  {
    id: "clqre993z00d5vboeh625fbl6",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:38:39.839Z",
    description:
      "Free device mockup presentation tool for your app and website design",
    isFavourited: false,
    link: "https://app.shotsnapp.com?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "shotsnapp",
    image: mylogo,
  },
  {
    id: "clqre9cda00dhvboety7e8rgt",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:38:44.062Z",
    description: "Mac app to collect, organize & share your colors",
    isFavourited: false,
    link: "https://sipapp.io/?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "Sip",
    image: mylogo,
  },
  {
    id: "clqre9g9x00dvvboe1ew8ilcn",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:38:49.125Z",
    description: "Enlarge and enhance images automatically using AI",
    isFavourited: false,
    link: "https://icons8.com/upscaler?ref=inspectresource.com",
    upvoteCount: 7,
    resourceName: "Smart Upscaler",
    image: mylogo,
  },
  {
    id: "clqre5jja0007vboefgghst49",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:35:46.726Z",
    description: "Version control for Sketch 💎",
    isFavourited: false,
    link: "https://www.abstract.com/?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Abstract",
    image: mylogo,
  },
  {
    id: "clqre91wu00cfvboe4inrkr2j",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:38:30.510Z",
    description: "Interactive prototyping for the digital products",
    isFavourited: false,
    link: "https://www.protopie.io?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "ProtoPie",
    image: mylogo,
  },
  {
    id: "clqre9e5100dnvboenwjf8x7h",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:38:46.357Z",
    description: "Collection of free and premium Sketch resources",
    isFavourited: false,
    link: "https://www.sketchappsources.com?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Sketch App Sources",
    image: mylogo,
  },
  {
    id: "clqre6df60033vboe7kd84cdj",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:36:25.459Z",
    description: "Open-source CSS, SVG and Figma UI Icons",
    isFavourited: false,
    link: "https://css.gg/?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "CSS.gg",
    image: mylogo,
  },
  {
    id: "clqre5p3r000rvboen6va8z1o",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:35:53.943Z",
    description: "1300 vector and 3D device mockups",
    isFavourited: false,
    link: "https://angle.sh?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Angle",
    image: mylogo,
  },
  {
    id: "clqre6cw70031vboeme1an6l8",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:36:24.775Z",
    description: "Leveraging leadership and individual voice",
    isFavourited: false,
    link: "https://www.amazon.co.uk/Creativity-Inc-Overcoming-Unseen-Inspiration/dp/0593070097/ref=as_li_ss_tl?_encoding=UTF8&qid=1586802461&sr=8-1&linkCode=ll1&tag=kylerdesign-21&linkId=26b52fe6c2bfccfe74918eaae96effaf&language=en_GB",
    upvoteCount: 6,
    resourceName: "Creativity Inc.",
    image: mylogo,
  },
  {
    id: "clqre6zhj0059vboehk14483t",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:54.056Z",
    description: "Complimentary colour generator powered by deep learning",
    isFavourited: false,
    link: "https://colors.eva.design/?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Eva Color Generator",
    image: mylogo,
  },
  {
    id: "clqrea3lq00g3vboekg5y5qsk",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:39:19.358Z",
    description: "Yo! is a celebration of great design and development online.",
    isFavourited: false,
    link: "https://robhope.com/yo?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Yo!",
    image: mylogo,
  },
  {
    id: "clqre7012005bvboecaid3rf8",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:36:54.758Z",
    description:
      "Evergreen is a pragmatic UI kit for building evolving products",
    isFavourited: false,
    link: "https://evergreen.segment.com?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Evergreen",
    image: mylogo,
  },
  {
    id: "clqre8ajs009xvboeze9zqqe2",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:55.048Z",
    description:
      "Delightful, beautifully crafted symbols for common actions and items",
    isFavourited: false,
    link: "https://material.io/icons?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Material Icons",
    image: mylogo,
  },
  {
    id: "clqre77mo0061vboezxmwlnlg",
    categoryId: "clqpwete9000evb3bucmzdlxb",
    createdAt: "2023-12-30T01:37:04.609Z",
    description: "User flows right inside your favorite design tool",
    isFavourited: false,
    link: "https://useflowkit.com/?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Flowkit",
    image: mylogo,
  },
  {
    id: "clqre6a5g002rvboe56l5yv2q",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:36:21.220Z",
    description: "A macOS app for quick access to WCAG color contrast ratios",
    isFavourited: false,
    link: "https://usecontrast.com/?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Contrast",
    image: mylogo,
  },
  {
    id: "clqre66p4002fvboea3ysd0p5",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:36:16.744Z",
    description: "A modern tool for exploring and finding accessible colors",
    isFavourited: false,
    link: "https://color.review/?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Color Review",
    image: mylogo,
  },
  {
    id: "clqre9kye00ebvboez6hv0moq",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:38:55.190Z",
    description: "Modern startup product development",
    isFavourited: false,
    link: "https://www.amazon.co.uk/Lean-Startup-Eric-Ries/dp/0307887898/ref=as_li_ss_tl?_encoding=UTF8&qid=1586805191&sr=8-1&linkCode=ll1&tag=kylerdesign-21&linkId=85d8036533cb3e828fe034b7c7921d75&language=en_GB",
    upvoteCount: 6,
    resourceName: "The Lean Startup",
    image: mylogo,
  },
  {
    id: "clqre7dob006nvboee232kanj",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:37:12.443Z",
    description: "Mockups for browsers, software and mobile devices",
    isFavourited: false,
    link: "https://frrames.com/?ref=inspectresource.com",
    upvoteCount: 6,
    resourceName: "Frrames",
    image: mylogo,
  },
  {
    id: "clqre7z7u008tvboepbutgtak",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:37:40.363Z",
    description: "Exposing bias in a world designed for men",
    isFavourited: false,
    link: "https://www.amazon.co.uk/Invisible-Women-Exposing-World-Designed-ebook/dp/B07CQ2NZG6/ref=as_li_ss_tl?dchild=1&keywords=invisible+women&qid=1586804035&sr=8-1&linkCode=ll1&tag=kylerdesign-21&linkId=85391876b9fb1ba45fa4c5c75d2235a8&language=en_GB",
    upvoteCount: 6,
    resourceName: "Invisible Women",
    image: mylogo,
  },
  {
    id: "clqre9gt900dxvboendvsl1hv",
    categoryId: "clqpwete9000evb3bucmzdlxb",
    createdAt: "2023-12-30T01:38:49.821Z",
    description: "Printable grids for design wireframing",
    isFavourited: false,
    link: "https://sneakpeekit.com?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Sneakpeekit",
    image: mylogo,
  },
  {
    id: "clqre6q5n004dvboexmnih7q8",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:36:41.963Z",
    description: "Jobs for Designers and Developers",
    isFavourited: false,
    link: "https://www.designernews.co/jobs/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Designer News Jobs",
    image: mylogo,
  },
  {
    id: "clqre64ga0027vboes5v83huh",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:36:13.835Z",
    description:
      "Daily inspiration collected from daily ui archive and beyond.",
    isFavourited: false,
    link: "http://collectui.com/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Collect UI",
    image: mylogo,
  },
  {
    id: "clqre7lgc007fvboetzv8ge0s",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:37:22.525Z",
    description: "Currated list of landing page examples from around the web",
    isFavourited: false,
    link: "https://www.h69.design/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "H69.Design",
    image: mylogo,
  },
  {
    id: "clqre73vp005pvboeys3gzhqm",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:36:59.749Z",
    description: "A collection of design resources for the figma community",
    isFavourited: false,
    link: "http://www.figmafreebi.es?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Figma Freebies",
    image: mylogo,
  },
  {
    id: "clqre7vur008hvboeh203y3xe",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:36.003Z",
    description:
      "A new online resource for all things digital product design. Created by InVision.",
    isFavourited: false,
    link: "https://www.invisionapp.com/inside-design/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Inside Design",
    image: mylogo,
  },
  {
    id: "clqre71nf005hvboexdn3qls5",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:36:56.860Z",
    description: "Images and Sketch files of popular devices.",
    isFavourited: false,
    link: "https://facebook.design/devices?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Facebook Devices",
    image: mylogo,
  },
  {
    id: "clqre5zng001rvboea1slrcif",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:07.613Z",
    description: "Tutorials and creative inspiration for designers",
    isFavourited: false,
    link: "https://www.briefbox.me?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Briefbox",
    image: mylogo,
  },
  {
    id: "clqre7xhc008nvboep6u89pg9",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:38.112Z",
    description: "An online magazine for creative professionals",
    isFavourited: false,
    link: "https://interfacelovers.com?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Interface Lovers",
    image: mylogo,
  },
  {
    id: "clqrea1d500fvvboeqn7mpb1x",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:39:16.458Z",
    description:
      "A designers interview series focusing on internships and job experiences",
    isFavourited: false,
    link: "https://anchor.fm/whiteboardfm?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Whiteboard.fm",
    image: mylogo,
  },
  {
    id: "clqre8k4h00atvboe2dttz1n9",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:38:07.458Z",
    description: "A podcast about working in design",
    isFavourited: false,
    link: "https://anchor.fm/opacity",
    upvoteCount: 5,
    resourceName: "Opacity",
    image: mylogo,
  },
  {
    id: "clqre6m76003zvboei936vknn",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:36:36.835Z",
    description: "A Figma publication for design systems",
    isFavourited: false,
    link: "https://www.designsystems.com/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Design Systems",
    image: mylogo,
  },
  {
    id: "clqre80e9008xvboem6t5k3u9",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:41.889Z",
    description:
      "Discover digital product design news and tips from today's leading designers.",
    isFavourited: false,
    link: "https://www.invisionapp.com/blog?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Invision.blog",
    image: mylogo,
  },
  {
    id: "clqre822z0093vboebncrieqo",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:37:44.075Z",
    description: "Showcasing beautiful icon designs from iOS",
    isFavourited: false,
    link: "https://www.iosicongallery.com/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "iOS Icon Gallery",
    image: mylogo,
  },
  {
    id: "clqre8nep00b5vboe3pvea5v6",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:38:11.713Z",
    description: "Record your prototype & app with device frame overlay",
    isFavourited: false,
    link: "https://overframe.xyz/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Overframe",
    image: mylogo,
  },
  {
    id: "clqre84cn009bvboelj8hxokk",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:37:47.015Z",
    description: "Landing page design inspiration from around the web",
    isFavourited: false,
    link: "https://www.lapa.ninja?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Lapa Ninja",
    image: mylogo,
  },
  {
    id: "clqre6iyp003nvboer8yyf8q5",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:36:32.641Z",
    description: "The best design jobs today",
    isFavourited: false,
    link: "https://www.designjobsboard.com?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Design Jobs Board",
    image: mylogo,
  },
  {
    id: "clqre62t60021vboe1i6mmjg4",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:11.707Z",
    description:
      "Graphic designer from New Zealand showing projects, concepts and vlogs",
    isFavourited: false,
    link: "https://www.youtube.com/user/charlimarieTV?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "CharliMarieTV",
    image: mylogo,
  },
  {
    id: "clqre9ot700epvboeyz1wylez",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:39:00.187Z",
    description: "Design inspiration for your projects",
    isFavourited: false,
    link: "https://uijar.com/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "UI Jar",
    image: mylogo,
  },
  {
    id: "clqre6tgh004nvboe7ig4n7l9",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:46.149Z",
    description:
      "A free library of psychological principles and examples for inspiration",
    isFavourited: false,
    link: "https://digitalpsychology.io?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Digital Psychology",
    image: mylogo,
  },
  {
    id: "clqre8wa900bvvboed2zz5u8w",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:38:23.218Z",
    description: "Shopify’s design system",
    isFavourited: false,
    link: "https://polaris.shopify.com?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Polaris",
    image: mylogo,
  },
  {
    id: "clqre89gi009tvboerl0u45x6",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:37:53.634Z",
    description:
      "Turn sketches, mockups and wireframes into interactive prototypes.",
    isFavourited: false,
    link: "https://marvelapp.com/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Marvel",
    image: mylogo,
  },
  {
    id: "clqre9npc00elvboeaek1seah",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:38:58.752Z",
    description: "Latest news from Uber design",
    isFavourited: false,
    link: "https://www.uber.design/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "Uber",
    image: mylogo,
  },
  {
    id: "clqre9lhp00edvboenjfdd9x2",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:38:55.886Z",
    description: "Why more is less",
    isFavourited: false,
    link: "https://www.amazon.co.uk/Paradox-Choice-Why-More-Less/dp/0060005688/ref=as_li_ss_tl?_encoding=UTF8&qid=1586804948&sr=8-1&linkCode=ll1&tag=kylerdesign-21&linkId=ae5d5f8566ee61cbc88133d31aa2347e&language=en_GB",
    upvoteCount: 5,
    resourceName: "The Paradox of Choice",
    image: mylogo,
  },
  {
    id: "clqre9kes00e9vboeh2uxx70t",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:38:54.484Z",
    description:
      "An online magazine exploring design, psychology & productivity.",
    isFavourited: false,
    link: "https://thedeskmagazine.com?ref=designresources",
    upvoteCount: 5,
    resourceName: "The DESK Magazine",
    image: mylogo,
  },
  {
    id: "clqre5vi9001dvboewws6l449",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:36:02.242Z",
    description:
      "A community of makers showcasing their startups and exchanging feedback",
    isFavourited: false,
    link: "https://betalist.com/?ref=inspectresource.com",
    upvoteCount: 5,
    resourceName: "BetaList",
    image: mylogo,
  },
  {
    id: "clqre5j0g0005vboedb222l5c",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:35:46.048Z",
    description: "Community-driven effort to make web accessibility easier",
    isFavourited: false,
    link: "https://a11yproject.com/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "A11y Project",
    image: mylogo,
  },
  {
    id: "clqre9qps00evvboewe7be5ej",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:39:02.653Z",
    description: "Curated digital marketplace for product designers",
    isFavourited: false,
    link: "https://ui8.net/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "UI8",
    image: mylogo,
  },
  {
    id: "clqre64zc0029vboe513r26bf",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:14.520Z",
    description: "Collection of unique colors for your future projects",
    isFavourited: false,
    link: "https://vanschneider.com/colors?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Color Claim",
    image: mylogo,
  },
  {
    id: "clqre8g3q00ahvboef9pbgt73",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:38:02.247Z",
    description:
      "The world’s largest collection of fonts for print, products & screens",
    isFavourited: false,
    link: "https://www.myfonts.com?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "MyFonts",
    image: mylogo,
  },
  {
    id: "clqre8tb200bnvboe9thk9sx6",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:38:19.262Z",
    description: "A unique way to generate fresh and vibrant colors",
    isFavourited: false,
    link: "https://pigment.shapefactory.co?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Pigment",
    image: mylogo,
  },
  {
    id: "clqre6aq8002tvboe2bm6wzwk",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:36:21.968Z",
    description: "Test many color combinations for WCAG 2.0 compliance",
    isFavourited: false,
    link: "https://contrast-grid.eightshapes.com?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Contrast Grid",
    image: mylogo,
  },
  {
    id: "clqre68b9002lvboe1jr1tbya",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:36:18.837Z",
    description: "Test and use color combinations that are accessible",
    isFavourited: false,
    link: "https://colorable.jxnblk.com/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Colorable",
    image: mylogo,
  },
  {
    id: "clqre8mv100b3vboe7c2udzcn",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:38:11.005Z",
    description: "Turn your designs into playable user flow diagrams",
    isFavourited: false,
    link: "https://overflow.io/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Overflow",
    image: mylogo,
  },
  {
    id: "clqre5yi4001nvboe489icfhm",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:06.124Z",
    description: "Biggest collection of official brand color codes",
    isFavourited: false,
    link: "https://brandcolors.net/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Brand Colors",
    image: mylogo,
  },
  {
    id: "clqre6owu0049vboef8mk2al5",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:36:40.350Z",
    description: "Follow & learn from talented designers",
    isFavourited: false,
    link: "https://www.designerhunt.io/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Designer Hunt",
    image: mylogo,
  },
  {
    id: "clqre9btu00dfvboeudnrintp",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:38:43.362Z",
    description: "1050 iOS style icons, ready for your next great idea...",
    isFavourited: false,
    link: "http://www.simpaticons.com/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Simpaticons",
    image: mylogo,
  },
  {
    id: "clqre7gdh006xvboea25rehz8",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:37:15.941Z",
    description: "Copy & paste glyphs with ease",
    isFavourited: false,
    link: "https://www.glyphy.io/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Glyphy",
    image: mylogo,
  },
  {
    id: "clqre96de00cvvboeu4wk00nt",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:38:36.290Z",
    description: "Online magazine for design students and free design awards",
    isFavourited: false,
    link: "https://www.rooki.design?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Rooki.design",
    image: mylogo,
  },
  {
    id: "clqre606x001tvboepjw9t3pm",
    categoryId: "clqpwetea000fvb3bxu5katf7",
    createdAt: "2023-12-30T01:36:08.313Z",
    description: "Beautiful copyright-free images powered by Shopify",
    isFavourited: false,
    link: "https://burst.shopify.com?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Burst",
    image: mylogo,
  },
  {
    id: "clqre6l4e003vvboeeyj49ro2",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:35.438Z",
    description: "Open source collection of Design Principles and methods",
    isFavourited: false,
    link: "https://principles.design?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Design Principles",
    image: mylogo,
  },
  {
    id: "clqre838m0097vboe77x6q6xc",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:37:45.574Z",
    description: "Websites to help find inspiration & motivation",
    isFavourited: false,
    link: "https://land-book.com/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Land Book",
    image: mylogo,
  },
  {
    id: "clqre90u300cbvboertvh5rho",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:38:29.116Z",
    description: "Design knowledge and inspiration",
    isFavourited: false,
    link: "https://productdesign.tips?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Productdesign.tips",
    image: mylogo,
  },
  {
    id: "clqre6kle003tvboegfoffu16",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:36:34.754Z",
    description: "Get design inspiration and insights from Google",
    isFavourited: false,
    link: "https://design.google/library/podcasts/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Design Notes",
    image: mylogo,
  },
  {
    id: "clqre6k28003rvboeqszh7fnf",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:36:34.065Z",
    description: "Podcast about design and creative culture",
    isFavourited: false,
    link: "https://www.designmattersmedia.com/designmatters",
    upvoteCount: 4,
    resourceName: "Design Matters",
    image: mylogo,
  },
  {
    id: "clqre63w90025vboe6zq7ftel",
    categoryId: "clqpwckl4000bvb3b2bhmajtx",
    createdAt: "2023-12-30T01:36:13.113Z",
    description: "Create mockups that make your design standout",
    isFavourited: false,
    link: "https://cleanmock.com/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Cleanmock",
    image: mylogo,
  },
  {
    id: "clqre9y1u00fjvboesw86mg39",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:39:12.162Z",
    description: "Comprehensive guide to landing your first UX job",
    isFavourited: false,
    link: "https://uxstarterpack.onfielder.com?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "UX Starter Pack",
    image: mylogo,
  },
  {
    id: "clqre88d2009pvboe2h8bl8n3",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:37:52.214Z",
    description: "Curated color palette inspiration",
    isFavourited: false,
    link: "https://www.webdesignrankings.com/resources/lolcolors?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "LOL Colors",
    image: mylogo,
  },
  {
    id: "clqre6f140039vboezrj4kd8g",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:36:27.545Z",
    description: "Showcasing the most beautifully designed dark mode websites",
    isFavourited: false,
    link: "https://www.darkmodedesign.com/?ref=inspectresource.com",
    upvoteCount: 4,
    resourceName: "Dark Mode Design",
    image: mylogo,
  },
  {
    id: "clqre7o64007pvboez00xicud",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:37:26.045Z",
    description: "Find the size of your browser window",
    isFavourited: false,
    link: "http://howbigismybrowser.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "How Big Is My Browser",
    image: mylogo,
  },
  {
    id: "clqre7d53006lvboebp7dd9au",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:11.750Z",
    description: "Checklist for modern websites and meticulous developers",
    isFavourited: false,
    link: "https://frontendchecklist.io?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Front End Checklist",
    image: mylogo,
  },
  {
    id: "clqre7e84006pvboeyyoopyc0",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:37:13.156Z",
    description: "Design system for FutureLearn",
    isFavourited: false,
    link: "https://design-system.futurelearn.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "FutureLearn",
    image: mylogo,
  },
  {
    id: "clqre75cp005tvboes3koknah",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:01.561Z",
    description: "Curated newsletter collecting interesting links about Figma",
    isFavourited: false,
    link: "https://figmalion.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Figmailion",
    image: mylogo,
  },
  {
    id: "clqre7ft3006vvboeo5t592yp",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:37:15.207Z",
    description: "The design system that powers GitHub",
    isFavourited: false,
    link: "https://primer.style?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Github Primer",
    image: mylogo,
  },
  {
    id: "clqre7i2c0073vboe3wbbzdyp",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:18.132Z",
    description: "Open-source resource for design leaders and product owners",
    isFavourited: false,
    link: "https://designsprintkit.withgoogle.com?rerf=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Google Sprints",
    image: mylogo,
  },
  {
    id: "clqre7jp50079vboeyrjn7i91",
    categoryId: "clqpwete9000evb3bucmzdlxb",
    createdAt: "2023-12-30T01:37:20.248Z",
    description: "Make your own grid paper",
    isFavourited: false,
    link: "http://gridzzly.com/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Gridzzly",
    image: mylogo,
  },
  {
    id: "clqre6xso0053vboehaau82ew",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:36:51.864Z",
    description: "eCommerce Website Design Gallery",
    isFavourited: false,
    link: "https://ecomm.design?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Ecomm.design",
    image: mylogo,
  },
  {
    id: "clqre7usp008dvboewstpjoqt",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:34.632Z",
    description: "Fully customisable & accessible vector icons",
    isFavourited: false,
    link: "https://ikonate.com/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Ikonate",
    image: mylogo,
  },
  {
    id: "clqre6odh0047vboev0h0te06",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:36:39.654Z",
    description:
      "Improve your design, branding, marketing, and advertising skills in one place",
    isFavourited: false,
    link: "https://pod.link/designedtoday?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Designed Today",
    image: mylogo,
  },
  {
    id: "clqre7wf5008jvboea9hid2q9",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:37:36.737Z",
    description:
      "Interviews from makers from the worlds of product design and startups",
    isFavourited: false,
    link: "https://www.intercom.com/blog/podcasts?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Inside Intercom",
    image: mylogo,
  },
  {
    id: "clqre81jq0091vboekmvbnus3",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:37:43.378Z",
    description: "Free collection of iOS 13 screens and components",
    isFavourited: false,
    link: "https://iosdesignkit.io/ios-13-gui/",
    upvoteCount: 3,
    resourceName: "iOS 13 UI Kit",
    image: mylogo,
  },
  {
    id: "clqre87tx009nvboev2sh625l",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:37:51.525Z",
    description: "Good design can tame complexity",
    isFavourited: false,
    link: "https://www.amazon.co.uk/Living-Complexity-Press-Donald-Norman-ebook/dp/B004ISL3R4/ref=as_li_ss_tl?dchild=1&keywords=living+with+complexity&qid=1586803662&sr=8-1&linkCode=ll1&tag=kylerdesign-21&linkId=3313aa0c1813ec6845a351b2d3df036b&language=en_GB",
    upvoteCount: 3,
    resourceName: "Living with Complexity",
    image: mylogo,
  },
  {
    id: "clqre8a0g009vvboeueavy4ey",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:37:54.352Z",
    description:
      "An inventory of UI components, design patterns and brand assets",
    isFavourited: false,
    link: "https://marvelapp.com/styleguide/overview/introduction?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Marvel Styleguide",
    image: mylogo,
  },
  {
    id: "clqre8b4f009zvboemwcvxko5",
    categoryId: "clqpwckl30005vb3bd3pajldj",
    createdAt: "2023-12-30T01:37:55.791Z",
    description: "Turn your prototype into actionable insights",
    isFavourited: false,
    link: "https://maze.design/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Maze",
    image: mylogo,
  },
  {
    id: "clqre8cqz00a5vboeiul8sqh8",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:37:57.899Z",
    description:
      "Daily curated, hand-picked collection of minimal web design inspiration",
    isFavourited: false,
    link: "https://minimal.gallery?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Minimal Gallery",
    image: mylogo,
  },
  {
    id: "clqre8dwc00a9vboen2rwpl8h",
    categoryId: "clqpwetea000fvb3bxu5katf7",
    createdAt: "2023-12-30T01:37:59.388Z",
    description: "Free assets for your next video project",
    isFavourited: false,
    link: "https://mixkit.co?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Mixkit",
    image: mylogo,
  },
  {
    id: "clqre6ehg0037vboealmha24y",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:26.837Z",
    description: "Web designer, author and all around great guy",
    isFavourited: false,
    link: "https://www.youtube.com/user/dannpetty/videos?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Dan Petty",
    image: mylogo,
  },
  {
    id: "clqre6dy80035vboe3y7m0jc1",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:26.144Z",
    description: "Thoughtfully crafted and easy-to-use color palettes",
    isFavourited: false,
    link: "https://www.culrs.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Curls",
    image: mylogo,
  },
  {
    id: "clqre8pcq00b9vboe2qhmer56",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:14.234Z",
    description: "Curation and discovery the best web pages",
    isFavourited: false,
    link: "https://www.pages.xyz?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Pages.xyz",
    image: mylogo,
  },
  {
    id: "clqre67ri002jvboe38amrvnq",
    categoryId: "clqpwceop0002vb3bn3073ugx",
    createdAt: "2023-12-30T01:36:18.126Z",
    description: "A simple tool that generates random colors and gradients",
    isFavourited: false,
    link: "https://colorspark.app?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Color Spark",
    image: mylogo,
  },
  {
    id: "clqre8rjq00bhvboex3sdut2a",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:17.078Z",
    description:
      "A curated gallery of patterns by awesome designers & illustrators",
    isFavourited: false,
    link: "https://patterncollect.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Pattern Collect",
    image: mylogo,
  },
  {
    id: "clqre90b400c9vboeux1ihapv",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:38:28.432Z",
    description:
      "A weekly podcast with the people creating and exploring the future",
    isFavourited: false,
    link: "https://www.producthunt.com/radio?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Product Hunt Radio",
    image: mylogo,
  },
  {
    id: "clqre91dp00cdvboe9513jg6f",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:38:29.821Z",
    description: "Create fully-interactive high-fidelity prototypes",
    isFavourited: false,
    link: "https://proto.io?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Proto.io",
    image: mylogo,
  },
  {
    id: "clqre92hi00chvboec2wqjwyc",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:38:31.254Z",
    description: "The best design news, handpicked every day.",
    isFavourited: false,
    link: "https://www.prototypr.io/home/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Prototypr",
    image: mylogo,
  },
  {
    id: "clqre93k500clvboe8i5dfqs2",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:32.645Z",
    description: "Curated collection of noteworthy email designs",
    isFavourited: false,
    link: "https://reallygoodemails.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Really Good Emails",
    image: mylogo,
  },
  {
    id: "clqre981f00d1vboezd6moutk",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:38:38.451Z",
    description:
      "Customizable, animated icons & illustrations exportable to code",
    isFavourited: false,
    link: "https://shape.so/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Shape.so",
    image: mylogo,
  },
  {
    id: "clqre5z14001pvboegt88m1yn",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:36:06.808Z",
    description: "Turn Figma prototypes into native iOS & Android apps",
    isFavourited: false,
    link: "https://www.bravostudio.app?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Bravo Studio",
    image: mylogo,
  },
  {
    id: "clqre9a6i00d9vboeycblbxhs",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:38:41.226Z",
    description: "Curated jobs from industry leaders",
    isFavourited: false,
    link: "https://sidebar.io/jobs?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Sidebar Jobs",
    image: mylogo,
  },
  {
    id: "clqrea44p00g5vboenkxccf5g",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:39:20.042Z",
    description: "User interface components for Zendesk products",
    isFavourited: false,
    link: "https://garden.zendesk.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Zendesk Garden",
    image: mylogo,
  },
  {
    id: "clqre9cwk00djvboe8zhrif5x",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:44.756Z",
    description: "Showcase of the finest web and interactive design",
    isFavourited: false,
    link: "https://www.siteinspire.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Site Inspire",
    image: mylogo,
  },
  {
    id: "clqre9fq400dtvboen7hz4fch",
    categoryId: "clqpwete9000evb3bucmzdlxb",
    createdAt: "2023-12-30T01:38:48.412Z",
    description: "Ready to print sketch sheet templates for UX designers",
    isFavourited: false,
    link: "https://sketchsheets.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Sketchsheets",
    image: mylogo,
  },
  {
    id: "clqre5ih70003vboe1bxw4asz",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:35:45.355Z",
    description: "A free collection of accessible color themes",
    isFavourited: false,
    link: "https://clrs.cc/a11y?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "90 Colors",
    image: mylogo,
  },
  {
    id: "clqre9hln00dzvboenn1q4xl8",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:38:50.681Z",
    description: "Generate human centric microcopy for all purposes",
    isFavourited: false,
    link: "https://www.speakhuman.today?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Speak Human",
    image: mylogo,
  },
  {
    id: "clqre9i4u00e1vboefnly1zxn",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:38:51.534Z",
    description: "A great community for Product Designers alike",
    isFavourited: false,
    link: "https://spectrum.chat/product-design?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Spectrum",
    image: mylogo,
  },
  {
    id: "clqre5xyj001lvboe48223r5x",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:36:05.419Z",
    description: "Carefully crafted and simple vector icons set",
    isFavourited: false,
    link: "https://boxicons.com/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Boxicons",
    image: mylogo,
  },
  {
    id: "clqre5wnv001hvboe8b9uwi4n",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:03.739Z",
    description: "Bite sized learning for creatives",
    isFavourited: false,
    link: "https://www.booklets.io?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Booklets.io",
    image: mylogo,
  },
  {
    id: "clqre5uvw001bvboeg2sev8qi",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:36:01.436Z",
    description: "UI/UX designer portfolios and more",
    isFavourited: false,
    link: "https://www.bestfolios.com/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Bestfolios",
    image: mylogo,
  },
  {
    id: "clqre9s0n00ezvboet05qxhtz",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:39:04.343Z",
    description: "",
    isFavourited: false,
    link: "https://www.amazon.co.uk/Universal-Principles-Design-Revised-Updated-ebook/dp/B00A3T5UO4/ref=as_li_ss_tl?dchild=1&keywords=universal+principles+of+design&qid=1586791464&sr=8-1&linkCode=ll1&tag=kylerdesign-21&linkId=7fdf8ecb8fa52914d1c020e97011dfa0&language=en_GB",
    upvoteCount: 3,
    resourceName: "Universal Principles of Design",
    image: mylogo,
  },
  {
    id: "clqre9unt00f7vboef3vz4bq1",
    categoryId: "clqpwetea000fvb3bxu5katf7",
    createdAt: "2023-12-30T01:39:07.769Z",
    description: "Library for user avatars to use in your UI",
    isFavourited: false,
    link: "https://userstock.io/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "UserStock",
    image: mylogo,
  },
  {
    id: "clqre5mbf000hvboe5oqtbyex",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:35:50.331Z",
    description:
      "Features the world’s leading type foundries and has thousands of beautiful fonts",
    isFavourited: false,
    link: "https://fonts.adobe.com/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Adobe Fonts",
    image: mylogo,
  },
  {
    id: "clqre5l81000dvboe4td8nip5",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:35:48.913Z",
    description: "Repository of publicly available design systems",
    isFavourited: false,
    link: "https://adele.uxpin.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Adele",
    image: mylogo,
  },
  {
    id: "clqre9xi000fhvboe21trgvpe",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:39:11.448Z",
    description: "Build your product based on evidence, not false beliefs",
    isFavourited: false,
    link: "https://uxmyths.com?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "UX Myths",
    image: mylogo,
  },
  {
    id: "clqre9ylr00flvboeqbro7luk",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:39:12.879Z",
    description: "Tool for designers to share work and feedback",
    isFavourited: false,
    link: "https://wake.com/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Wake",
    image: mylogo,
  },
  {
    id: "clqre772c005zvboezpe6ab05",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:37:03.877Z",
    description: "How to achieve happiness",
    isFavourited: false,
    link: "https://www.amazon.co.uk/Flow-Psychology-Happiness-Mihaly-Csikszentmihalyi-ebook/dp/B00GO8HZIW/ref=as_li_ss_tl?dchild=1&keywords=Flow:+The+Psychology+of+Happiness&qid=1586805829&sr=8-1&linkCode=ll1&tag=kylerdesign-21&linkId=98e347e57ea21a04d426ed1c0d4a25f7&language=en_GB",
    upvoteCount: 3,
    resourceName: "Flow",
    image: mylogo,
  },
  {
    id: "clqre79th0069vboesq6tgvjz",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:37:07.445Z",
    description: "High quality, legitimately free fonts",
    isFavourited: false,
    link: "https://www.fontsquirrel.com/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Font Squirrel",
    image: mylogo,
  },
  {
    id: "clqre7avq006dvboei0kg8r6u",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:37:08.822Z",
    description: "A searchable archive of typographic design",
    isFavourited: false,
    link: "https://fontsinuse.com/?ref=inspectresource.com",
    upvoteCount: 3,
    resourceName: "Fonts in Use",
    image: mylogo,
  },
  {
    id: "clqre7ym2008rvboe13nz0p7l",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:39.578Z",
    description: "Learn the fundamentals of icon design",
    isFavourited: false,
    link: "http://introtoicons.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Intro to Icons",
    image: mylogo,
  },
  {
    id: "clqre959y00crvboeigqmtxi1",
    categoryId: "clqpwetea000fvb3bxu5katf7",
    createdAt: "2023-12-30T01:38:34.775Z",
    description: "Tool for removing image backgrounds easily",
    isFavourited: false,
    link: "https://www.remove.bg/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Remove Background",
    image: mylogo,
  },
  {
    id: "clqre95ti00ctvboesf9mik4p",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:38:35.574Z",
    description: "Professional font manager app for Mac",
    isFavourited: false,
    link: "https://rightfontapp.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "RightFont 5",
    image: mylogo,
  },
  {
    id: "clqre75vn005vvboe89tg9u42",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:02.339Z",
    description: "The fastest way to brand assets",
    isFavourited: false,
    link: "http://www.findguidelin.es?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Findguidelin.es",
    image: mylogo,
  },
  {
    id: "clqre9o9i00envboetrrfjoot",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:38:59.478Z",
    description: "Discover free design assets from independent authors",
    isFavourited: false,
    link: "https://uibundle.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "UI Bundle",
    image: mylogo,
  },
  {
    id: "clqre8nyb00b7vboeho28smqw",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:38:12.418Z",
    description:
      "Dribbble’s official podcast goes behind the shots of your favorite designers",
    isFavourited: false,
    link: "https://dribbble.com/overtime?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Overtime",
    image: mylogo,
  },
  {
    id: "clqre7mje007jvboe7kks7cew",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:23.930Z",
    description: "Open source icons from the creators of Tailwind CSS",
    isFavourited: false,
    link: "https://heroicons.dev?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Heroicons",
    image: mylogo,
  },
  {
    id: "clqre8pvm00bbvboectmz26pf",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:14.915Z",
    description: "Keep up to date with design and news sources",
    isFavourited: false,
    link: "http://usepanda.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Panda",
    image: mylogo,
  },
  {
    id: "clqrea1yc00fxvboemcv5d0ht",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:39:17.220Z",
    description: "Accessible color combination tester with different scenarios",
    isFavourited: false,
    link: "https://whocanuse.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Who Can Use",
    image: mylogo,
  },
  {
    id: "clqre6ycx0055vboewc15hobg",
    categoryId: "clqpwckl40009vb3b31fslsa9",
    createdAt: "2023-12-30T01:36:52.593Z",
    description: "Discover jobs for design",
    isFavourited: false,
    link: "https://epicjobs.co?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Epic Jobs",
    image: mylogo,
  },
  {
    id: "clqre68xa002nvboe0j18iz73",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:36:19.630Z",
    description: "Produce accessible color sets",
    isFavourited: false,
    link: "https://www.colorbox.io?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Colorbox",
    image: mylogo,
  },
  {
    id: "clqre8sn100blvboek4ev699m",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:38:18.493Z",
    description: "Design and prototype websites and apps intuitively",
    isFavourited: false,
    link: "https://phase.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Phase",
    image: mylogo,
  },
  {
    id: "clqre6cbb002zvboepovv1r56",
    categoryId: "clqpwetea000fvb3bxu5katf7",
    createdAt: "2023-12-30T01:36:24.023Z",
    description: "Download royalty free video stock footage",
    isFavourited: false,
    link: "https://www.coverr.co/?ref=desiggnresourc.es",
    upvoteCount: 2,
    resourceName: "Coverr",
    image: mylogo,
  },
  {
    id: "clqre8eg600abvboe6097szdy",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:38:00.103Z",
    description: "Mixpanel Design System for design and development",
    isFavourited: false,
    link: "https://design.mixpanel.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Mixpanel",
    image: mylogo,
  },
  {
    id: "clqre8dae00a7vboemm71qn91",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:37:58.598Z",
    description:
      "How inclusive methods can build elegant design solutions that work for all",
    isFavourited: false,
    link: "https://amzn.to/2Y0jYAy",
    upvoteCount: 2,
    resourceName: "Mismatch",
    image: mylogo,
  },
  {
    id: "clqre6goy003fvboezznbxc9b",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:36:29.699Z",
    description:
      "Ran Segall chats with successful, inspiring, smart designers around the world",
    isFavourited: false,
    link: "http://www.designblinddate.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Design Blind Date",
    image: mylogo,
  },
  {
    id: "clqre8xd900bzvboebm1v6lio",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:38:24.621Z",
    description:
      "How we design and build the products that are shaping our digital future",
    isFavourited: false,
    link: "https://www.relay.fm/presentable?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Presentable",
    image: mylogo,
  },
  {
    id: "clqre8796009lvboe63ks0p4b",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:50.778Z",
    description: "2000+ Handcrafted Line Icons for Modern User Interfaces",
    isFavourited: false,
    link: "https://lineicons.com/",
    upvoteCount: 2,
    resourceName: "Line Icons",
    image: mylogo,
  },
  {
    id: "clqrea2i900fzvboeqdlwlza4",
    categoryId: "clqpwete9000evb3bucmzdlxb",
    createdAt: "2023-12-30T01:39:17.937Z",
    description: "Design tool fine tuned for wireframing",
    isFavourited: false,
    link: "https://wireframe.cc?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Wireframe.cc",
    image: mylogo,
  },
  {
    id: "clqre86o7009jvboel8w6zgst",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:50.023Z",
    description: "A complete approach to learning UI & UX Tools.",
    isFavourited: false,
    link: "https://learnux.io/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "LearnUX.io",
    image: mylogo,
  },
  {
    id: "clqre7kuq007dvboe3ns6quz3",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:21.746Z",
    description:
      "A curated collection of 250+ resources & tools dedicated to user research",
    isFavourited: false,
    link: "http://guidetouxr.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Guide to UXR",
    image: mylogo,
  },
  {
    id: "clqre83sv0099vboe3hzsimhr",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:37:46.303Z",
    description: "Landing page design inspiration from around the web",
    isFavourited: false,
    link: "https://www.lapa.ninja/learn/",
    upvoteCount: 2,
    resourceName: "Lapa Learn",
    image: mylogo,
  },
  {
    id: "clqre930t00cjvboech12lf4n",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:31.949Z",
    description: "Design patterns, resources and inspiration",
    isFavourited: false,
    link: "https://www.pttrns.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Pttrns",
    image: mylogo,
  },
  {
    id: "clqre5nfe000lvboefsu2poct",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:35:51.770Z",
    description:
      "Behind the scenes of Airbnb's creative culture through the lens of our team.",
    isFavourited: false,
    link: "https://airbnb.design/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Airbnb",
    image: mylogo,
  },
  {
    id: "clqre7wyd008lvboebjaz2nwc",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:37:37.430Z",
    description: "Search for and download logos of well-known brands",
    isFavourited: false,
    link: "http://instantlogosearch.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Instant Logo Search",
    image: mylogo,
  },
  {
    id: "clqre9tkc00f3vboemyvbu55v",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:39:06.244Z",
    description:
      "The best of design & development inspiration, resources and freebies",
    isFavourited: false,
    link: "https://www.uplabs.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Uplabs",
    image: mylogo,
  },
  {
    id: "clqrea32c00g1vboemknuetzq",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:39:18.660Z",
    description: "Directory of accomplished women in the design industry",
    isFavourited: false,
    link: "https://womenwho.design?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Women Who Design",
    image: mylogo,
  },
  {
    id: "clqre7fa2006tvboe2jikq7f3",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:37:14.523Z",
    description: "Find and download all the fonts that you want directly",
    isFavourited: false,
    link: "https://www.getthefont.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Get the Font",
    image: mylogo,
  },
  {
    id: "clqre9aqr00dbvboe5mduum6c",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:41.955Z",
    description: "Currated collection of websites",
    isFavourited: false,
    link: "https://siiimple.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Siiimple",
    image: mylogo,
  },
  {
    id: "clqre7tny0089vboez74bi8tr",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:33.072Z",
    description: "Free, cross-platform SVG icon manager",
    isFavourited: false,
    link: "https://iconset.io?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Iconset",
    image: mylogo,
  },
  {
    id: "clqre7rzv0083vboe7jjx79du",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:31.003Z",
    description: "A collection of 2059 SVG Icons",
    isFavourited: false,
    link: "https://iconoir.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Iconoir",
    image: mylogo,
  },
  {
    id: "clqre6r65004fvboe2lqz56ys",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:36:43.277Z",
    description: "Collection of free resources for designers",
    isFavourited: false,
    link: "https://www.designermill.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "DesignerMill",
    image: mylogo,
  },
  {
    id: "clqre9eoe00dpvboerjye6fav",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:38:47.054Z",
    description: "Discover Sketch resources for your next design project",
    isFavourited: false,
    link: "https://sketchrepo.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Sketch Repo",
    image: mylogo,
  },
  {
    id: "clqre9f7i00drvboe4514adjg",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:38:47.742Z",
    description:
      "All things related to User Experience and User Interface design",
    isFavourited: false,
    link: "https://www.youtube.com/channel/UCZHkx_OyRXHb1D3XTqOidRw/featured",
    upvoteCount: 2,
    resourceName: "Sketch Together",
    image: mylogo,
  },
  {
    id: "clqre9wyu00ffvboed3ca161m",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:39:10.758Z",
    description:
      "Learn new and innovative best practices in user experience design",
    isFavourited: false,
    link: "https://uxmovement.com?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "UX Movement",
    image: mylogo,
  },
  {
    id: "clqre7bis006fvboegaj5blzq",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:09.652Z",
    description: "Repository of news for design, development and art",
    isFavourited: false,
    link: "https://fragments.pro?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Fragments",
    image: mylogo,
  },
  {
    id: "clqre6rs4004hvboerywcir4h",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:36:44.069Z",
    description: "The best documentaries for designers and artists",
    isFavourited: false,
    link: "https://designers.watch?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Designers.watch",
    image: mylogo,
  },
  {
    id: "clqre6sub004lvboeeteae92i",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:36:45.443Z",
    description: "Web design articles, tutorials and more",
    isFavourited: false,
    link: "https://designmodo.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Designmodo",
    image: mylogo,
  },
  {
    id: "clqre7pam007tvboe7ai7r3cd",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:37:27.502Z",
    description: "Inspiration resource showcasing totally rocking websites",
    isFavourited: false,
    link: "https://httpster.net?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Httpster",
    image: mylogo,
  },
  {
    id: "clqre7ops007rvboexun33py7",
    categoryId: "clqpwbyzb0001vb3bto2y9dy2",
    createdAt: "2023-12-30T01:37:26.752Z",
    description: "Book about philosophy, design, humanity, and our future",
    isFavourited: false,
    link: "https://amzn.to/2X1cIC9",
    upvoteCount: 2,
    resourceName: "How to Speak Machine",
    image: mylogo,
  },
  {
    id: "clqre9m2800efvboefbs4pp60",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:38:56.624Z",
    description: "A showcase of type foundries and designers",
    isFavourited: false,
    link: "https://type.lol?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Type Foundry Index",
    image: mylogo,
  },
  {
    id: "clqre9zpy00fpvboe93n5qafr",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:39:14.326Z",
    description: "A curated collection of the best news for designers each day",
    isFavourited: false,
    link: "https://www.webdesignernews.com/?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Web Designer News",
    image: mylogo,
  },
  {
    id: "clqre5rwv0011vboeoyrebunu",
    categoryId: "clqpwckl30004vb3bi0vrqj22",
    createdAt: "2023-12-30T01:35:57.583Z",
    description: "Atlassian's design system",
    isFavourited: false,
    link: "https://atlassian.design?ref=inspectresource.com",
    upvoteCount: 2,
    resourceName: "Atlassian Design",
    image: mylogo,
  },
  {
    id: "clqre799e0067vboeezy0utj9",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:37:06.722Z",
    description: "High-quality typefaces, calligraphy and lettering",
    isFavourited: false,
    link: "https://www.fontfabric.com/free-fonts?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Font Fabric",
    image: mylogo,
  },
  {
    id: "clqre70kl005dvboe38n3xv8e",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:36:55.461Z",
    description: "Collection of 460+ well-crafted icons",
    isFavourited: false,
    link: "http://www.evericons.com?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Evericons",
    image: mylogo,
  },
  {
    id: "clqre97hv00czvboetgg2j04n",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:38:37.747Z",
    description: "Curated collection of app store screenshots",
    isFavourited: false,
    link: "https://scrnshts.club?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Scrnshts",
    image: mylogo,
  },
  {
    id: "clqre7acy006bvboeg3xqub1e",
    categoryId: "clqqy1ssm0000vbn8g0dx05ls",
    createdAt: "2023-12-30T01:37:08.146Z",
    description: "Free fonts and alternatives to premium fonts",
    isFavourited: false,
    link: "https://fontsarena.com?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Fonts Arena",
    image: mylogo,
  },
  {
    id: "clqre7y0u008pvboetrgvqbxj",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:37:38.814Z",
    description: "Hand-picked collection of latest mobile design patterns",
    isFavourited: false,
    link: "https://interfaces.pro/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Interfaces Pro",
    image: mylogo,
  },
  {
    id: "clqre7u8n008bvboeuslv5v9m",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:37:33.911Z",
    description: "Quick customizable icons for your projects",
    isFavourited: false,
    link: "https://iconsvg.xyz?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "ICONSVG",
    image: mylogo,
  },
  {
    id: "clqre9u3j00f5vboeddail09t",
    categoryId: "clqpwckl40008vb3ba41vsbz9",
    createdAt: "2023-12-30T01:39:07.038Z",
    description: "Source for User Onboarding teardowns",
    isFavourited: false,
    link: "https://www.useronboard.com/user-onboarding-teardowns/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "UserOnboard",
    image: mylogo,
  },
  {
    id: "clqre8lsf00azvboe9a4o1j7l",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:38:09.615Z",
    description: "Discover a huge collection of exclusive premium mockups",
    isFavourited: false,
    link: "https://originalmockups.com/mockups/free-mockups?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Original Mockups",
    image: mylogo,
  },
  {
    id: "clqre943k00cnvboeipyd0diw",
    categoryId: "clqpwckl4000avb3b7me9u31c",
    createdAt: "2023-12-30T01:38:33.345Z",
    description: "Screenshots and examples of great UX from products",
    isFavourited: false,
    link: "https://www.reallygoodux.io/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Really Good UX",
    image: mylogo,
  },
  {
    id: "clqre9bar00ddvboe24oamwwi",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:38:42.671Z",
    description: "A color blindness simulator",
    isFavourited: false,
    link: "https://michelf.ca/projects/sim-daltonism?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Sim Daltonism",
    image: mylogo,
  },
  {
    id: "clqre665a002dvboem8b772kd",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:36:16.030Z",
    description: "Free color blindness simulator",
    isFavourited: false,
    link: "https://colororacle.org?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Color Oracle",
    image: mylogo,
  },
  {
    id: "clqre9v7n00f9vboeupxicwv2",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:39:08.483Z",
    description:
      "Digital products, services and companies. We learn through making.",
    isFavourited: false,
    link: "https://ustwo.com/blog?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Ustwo.blog",
    image: mylogo,
  },
  {
    id: "clqre76fk005xvboeg8l98y53",
    categoryId: "clqpwetea000hvb3bhuy19oug",
    createdAt: "2023-12-30T01:37:03.056Z",
    description: "Flinto is a Mac app used by top designers around the world",
    isFavourited: false,
    link: "https://www.flinto.com/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Flinto",
    image: mylogo,
  },
  {
    id: "clqre5nzf000nvboe6bw6wkmc",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:35:52.491Z",
    description: "Simple collection of all design and front-end conferences",
    isFavourited: false,
    link: "https://www.alldesignconferences.com/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "All Design Conferences",
    image: mylogo,
  },
  {
    id: "clqre5q6k000vvboefmboxubv",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:35:55.341Z",
    description: "Design email newsletter from Pablo Stanley",
    isFavourited: false,
    link: "https://www.anotherdesignnewsletter.com/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Another Design",
    image: mylogo,
  },
  {
    id: "clqre8yz600c5vboetkf21crc",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:38:26.707Z",
    description:
      "A half-monthly newsletter with curated stories on design and technology",
    isFavourited: false,
    link: "https://productdisrupt.com/newsletter.html?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Product Disrupt Newsletter",
    image: mylogo,
  },
  {
    id: "clqre6lny003xvboevr2giqgc",
    categoryId: "clqpwckl4000cvb3b6ng3as0u",
    createdAt: "2023-12-30T01:36:36.143Z",
    description: "Grow your knowledge in all things digital design",
    isFavourited: false,
    link: "http://www.designstandup.com/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Design Standup",
    image: mylogo,
  },
  {
    id: "clqre7clr006jvboezmb78nh8",
    categoryId: "clqpwetea000gvb3bxlwiq1ez",
    createdAt: "2023-12-30T01:37:11.053Z",
    description: "Free, high quality design resources",
    isFavourited: false,
    link: "https://freebiesupply.com/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Freebie Supply",
    image: mylogo,
  },
  {
    id: "clqre8wtw00bxvboe9tovtw16",
    categoryId: "clqpwckl40006vb3bo7x08570",
    createdAt: "2023-12-30T01:38:23.924Z",
    description:
      "A collection of simple and informative icons from Shopify's design system",
    isFavourited: false,
    link: "https://polaris-icons.shopify.com/?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "Polaris Icons",
    image: mylogo,
  },
  {
    id: "clqre7qwn007zvboexq7b4ss8",
    categoryId: "clqpwckl30003vb3b0wts8kvd",
    createdAt: "2023-12-30T01:37:29.591Z",
    description: "Learn about IBM's design philosophy",
    isFavourited: false,
    link: "https://www.ibm.com/design/practices?ref=inspectresource.com",
    upvoteCount: 1,
    resourceName: "IBM Design",
    image: mylogo,
  },
  {
    id: "clqre5kop000bvboe8w5axcpf",
    categoryId: "clqpwbsp50000vb3b1vghb920",
    createdAt: "2023-12-30T01:35:48.218Z",
    description: "Create ADA compliant colors",
    isFavourited: false,
    link: "https://abc.useallfive.com?ref=inspectresource.com",
    upvoteCount: 0,
    resourceName: "Accessible Brand Colors",
    image: mylogo,
  },
];
