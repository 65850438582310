import aboutMe from "../../assets/img/3d-developer-whole-body.png";

const AboutMe = () => {
  return (
    <div className="m-10 my-36">
      <div className="flex justify-center gap-3 items-end">
        <figure className="w-[35rem]">
          <img
            src={aboutMe}
            alt="ME"
            className=" rounded-2xl "
            style={{ transform: "scaleX(-1)" }}
          />
        </figure>
        <div className="">
          <div className="mockup-code w-[40rem] leading-6">
            <pre data-prefix="1">
              <code className="text-3xl">Where it all started.</code>
            </pre>
            <pre data-prefix="2">
              <code></code>
            </pre>
            <pre data-prefix="3">
              <code>
                In 2018, I began my journey as a full-stack developer,
              </code>
            </pre>
            <pre data-prefix="4">
              <code>focusing primarily on front-end development.</code>
            </pre>
            <pre data-prefix="5">
              <code>Exploring HTML, CSS, and JavaScript,</code>
            </pre>
            <pre data-prefix="6">
              <code>
                I tackled challenges and delved into technologies like
              </code>
            </pre>
            <pre data-prefix="7">
              <code>
                <span className="text-[#00d2f7] font-bold">ReactJs</span> and{" "}
                <span className="text-[#3fb37f] font-bold">VueJs</span>.
                Engaging with the frontend community,
              </code>
            </pre>
            <pre data-prefix="8">
              <code>I shared insights and forged connections,</code>
            </pre>
            <pre data-prefix="9">
              <code>driven by a passion for innovation.</code>
            </pre>
            <pre data-prefix="10">
              <code>Since then, my career has been marked by growth</code>
            </pre>
            <pre data-prefix="11">
              <code>and a relentless pursuit of excellence in</code>
            </pre>
            <pre data-prefix="12">
              <code>front-end development.</code>
            </pre>
            <pre data-prefix="15">
              <code></code>
            </pre>
          </div>
          <div className="card-actions justify-between items-center pt-4">
            <div className="stats stats-vertical lg:stats-horizontal shadow bg-base-200">
              <div className="stat">
                <div className="stat-title">Experience</div>
                <div className="stat-value py-2">5+ </div>
                <div className="stat-desc">Years</div>
              </div>

              <div className="stat">
                <div className="stat-title">Projects</div>
                <div className="stat-value py-2">10+</div>
                <div className="stat-desc">Completed</div>
              </div>

              <div className="stat">
                <div className="stat-title">Companies</div>
                <div className="stat-value py-2">5+</div>
                <div className="stat-desc">Worked</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
