import { Navigate, Route, Routes } from "react-router-dom";
import Creator from "../views/creator";
import Resoures from "../views/resources";

const WebRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/tools" />} />
      <Route path="/portfolio" element={<Creator />} />
      <Route path="/tools" element={<Resoures />} />
    </Routes>
  );
};

export default WebRoutes;
