import { useEffect, useRef, useState } from "react";
import { categories } from "../../data/data";
import Header from "../common/header";
import Tools from "../resources/Tools";
interface MenuData {
  id: string;
  name: string;
  icon: any;
  createdAt: string;
}

const Resoures = () => {
  const [activeMenu, setActiveMenu] = useState("all");
  const [menu, setMenu] = useState<MenuData[]>(categories);
  const noticRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    window.document.title = "Inspect Resources";
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      noticRef.current?.showModal();
    }
  }, [window.innerWidth]);

  const renderAlert = () => {
    return (
      <dialog ref={noticRef} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Hi this is Inspect!</h3>
          <p className="py-4">
            The mobile version is currently underdevelopment. <br /> Thank you
            for your patience.
          </p>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
    );
  };

  const renderMenu = (menu: MenuData, index: number) => {
    return (
      <li key={index}>
        <a
          className={activeMenu === menu.id ? "active" : ""}
          onClick={() => setActiveMenu(menu.id)}
        >
          {menu.name}
        </a>
      </li>
    );
  };

  return (
    <>
      <Header title="Inspect Resource" />
      <div className="flex justify-center mt-20">
        <div className="w-5/6 flex justify-between">
          <ul className="menu bg-base-200 w-[14rem] max-h-[60rem] rounded-lg mr-5">
            <li key="all">
              <a
                className={activeMenu === "all" ? "active" : ""}
                onClick={() => setActiveMenu("all")}
              >
                All
              </a>
            </li>
            {menu.map(renderMenu)}
          </ul>
          <Tools activeMenu={activeMenu} />
        </div>
      </div>
      {renderAlert()}
    </>
  );
};

export default Resoures;
